/* eslint-disable */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import List from "./list";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {setUserGiftcardlist } from "../../../../../redux/actions/action";
import {  user_giftcard_listing } from "../../../../api/websocket/payload";
import TableLayout from "../../../components/TableLayout";
import { initialPage } from "../../../../utils/staticdata";



const UserGiftCard = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === user_giftcard_listing.url) {
          if(receivedMessage?.response?.status===200)
          dispatch(setUserGiftcardlist(receivedMessage?.response?.data));
          if(receivedMessage?.response?.status===400)
            dispatch(setUserGiftcardlist(initialPage));
        }
      }
    }

 
  }, [receivedMessage, isConnect]);

  useEffect(() => {
    if (isConnect) {
      const message = user_giftcard_listing
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      user_giftcard_listing.request = {
        limit: 10,
        page: 1,
      };
    };
  }, [])

  return (
    <TableLayout
      isFilter
      isAdd={false}
      title={"Payment List"}
      payload={user_giftcard_listing}
    >
      <List />
    </TableLayout>
  );
};

export default UserGiftCard;
