import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import ReactQuill from "react-quill";

interface ITextEditor{
    field:ControllerRenderProps<any, any>
    errors?:any
}
const TextEditor:React.FC<ITextEditor> = (prop) => {
  const modules = {
    toolbar: [
      [{ font: [] }, { size: ["small", false, "large", "huge"] }],  // Add size options
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "font",
    "size",  // Make sure size is included in the formats
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "background",
    "align",
    "link",
    "image",
  ];

  return (
    <>
    <ReactQuill
      theme="snow"
      // className="lg:h-64 h-96"
      {...prop.field}
      modules={modules}
      formats={formats}
      
    />
    <div className="mt-20 error_message_description">
    {prop.errors && (
          <p className="text-xs  mx-1  text-red-500 mt-1">{prop.errors.message}</p>
        )}
    </div>
    </>
  );
};

export default TextEditor;
