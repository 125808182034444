import { AiOutlineDelete } from "react-icons/ai";
import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import { feedback_lists } from "../../../../api/websocket/payload";

interface IList {

  toggleWarn: Function;

  
}

const List: React.FunctionComponent<IList> = (props) => {
  const { feedback_list } = usePosterReducers();

  const header = [
    "Name",
    "email",
    "comment",
    "Created At",
    
    "Actions",
  ];
  return (
    <ListComp payLoad={feedback_lists} pages={feedback_list?.pagination} header={header}>
      
        {feedback_list?.data?.length !== 0 &&
          feedback_list?.data?.map((item, index) => (
            
            <tr key={index}  className="border-b text-sm rounded-md  ">
           
                <td className="p-3">{item.name}</td>
                <td>{item.email}</td>
                <td>{item.comment}</td>
                <td>{dateFormat(item.created_at)}</td>
              
                
                <td>
                  <div className="flex justify-center items-center gap-2">
                    
                    <button
                      onClick={() => props.toggleWarn("",item.id,'delete')}
                      className="p-2  text-base text-black rounded-md"
                    >
                      <AiOutlineDelete />
                    </button>
                  </div>
                </td>
              </tr>
            
          ))}
    
    </ListComp>
  );
};

export default List;
