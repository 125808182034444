/* eslint-disable */
import { useEffect } from "react";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cart_details } from "../../../api/websocket/payload";
import { setOrderDetail } from "../../../../redux/actions/action";
import { dateFormat } from "../../../utils/common";
import { initialOrderDetail } from "../../../utils/staticdata";
import ListComp from "../../components/ListComp";
import GiftImage from "../../../asset/images/Group90.webp";

const ViewOrder = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const { order_detail } = usePosterReducers();
  const dispatch = useDispatch();
  const param = useParams();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === cart_details.url) {
          console.log(order_detail);
          
          dispatch(setOrderDetail(receivedMessage?.response?.data));
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect && param?.id) {
      cart_details.request.order_id = param.id;
      send(cart_details);
    }
    return () => {
      dispatch(setOrderDetail(initialOrderDetail));
    };
  }, [isConnect]);


  return (
    <div className="p-2 px-3 overflow-x-hidden h-full text-sm">
      <p className="font-semibold text-lg text-gray-500 my-1 mt-3 md:my-0">
        Order Details
      </p>

      <div className="mt-3 md:mt-0">
        <div className="lg:grid grid-cols-2">
          <div>
            {order_detail?.username && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">User Name </p>:
                <p>{order_detail?.username}</p>
              </div>
            )}

            {order_detail?.email && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium"> Email </p>:
                <p>{order_detail?.email}</p>
              </div>
            )}
            {order_detail?.order_type && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Order Type </p>:
                <p>{order_detail?.order_type}</p>
              </div>
            )}
            {order_detail?.total_amount && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Total Amount. </p>:
                <p>
                  ${" "}
                  {order_detail?.total_amount &&
                    (Number(order_detail?.total_amount) / 100).toFixed(2)}
                </p>
              </div>
            )}
            {/* {order_detail?.order_note && ( */}
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Order Note </p>:
                <p style={{ float: "left", width: "100%", wordBreak: "break-word" }}>{(order_detail?.order_note === null || order_detail?.order_note === "") ? "-" : order_detail?.order_note}</p>
              </div>
            {/* )} */}
            {/* {order_detail?.promo_coupon && ( */}
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Promo Coupon </p>:
                <p>{order_detail?.promo_coupon === null ? "-" : order_detail?.promo_coupon}</p>
              </div>
            {/* )} */}
            
            {/* {order_detail?.gift_coupon && ( */}
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Gift Coupon </p>:
                <p>{order_detail?.gift_coupon === null ? "-" : order_detail?.gift_coupon}</p>
              </div>
            {/* )} */}
            {order_detail?.delivery_date && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Delivery Date </p>:
                <p>{dateFormat(order_detail?.delivery_date)}</p>
              </div>
            )}
            {order_detail?.transaction_id && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Transaction Id </p>:
                <p>{order_detail?.transaction_id}</p>
              </div>
            )}

            {order_detail?.created_at && (
              <div className="flex gap-2 my-2">
                <p className="min-w-24 font-medium">Created Date </p>:
                <p>
                  {dateFormat(
                    order_detail?.created_at ? order_detail.created_at : ""
                  )}
                </p>
              </div>
            )}

            <div className="flex gap-2 my-2">
              <p className="min-w-24 font-medium"> Payment Status </p>:
              <p>{order_detail?.is_paid ? "Paid" : "Unpaid"}</p>
            </div>
          </div>
          <div className="">
            <p className="font-semibold text-lg text-gray-500 my-1 mt-3 md:my-0">
              Addresses
            </p>
            <div className="lg:grid grid-cols-2 ">
              <div className="mx-2">
                <p className="font-medium   text-gray-500">Billing Information</p>
             
                {order_detail?.details?.customer_info?.company_name && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Company name </p>:
                    <p>{order_detail?.details?.customer_info.company_name === null ? "-" : order_detail?.details?.customer_info.company_name}</p>
                  </div>
                )}
                {order_detail?.details?.billing_details.apartment && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Appartment </p>:
                    <p>{order_detail?.details?.billing_details.apartment}</p>
                  </div>
                )}
                {order_detail?.details?.billing_details.city && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> City </p>:
                    <p>{order_detail?.details?.billing_details.city}</p>
                  </div>
                )}
                {order_detail?.details?.billing_details.state && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> State </p>:
                    <p>{order_detail?.details?.billing_details.state}</p>
                  </div>
                )}
                {order_detail?.details?.billing_details.country && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Country </p>:
                    <p>{order_detail?.details?.billing_details.country}</p>
                  </div>
                )}
                {order_detail?.details?.billing_details.zipcode && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Zip Code </p>:
                    <p>{order_detail?.details?.billing_details.zipcode}</p>
                  </div>
                )}
              </div>

              <div className="mx-3 ">
                <p className="font-medium   text-gray-500">Shipping Information</p>
             
                {order_detail?.details?.customer_info?.company_name && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Company name </p>:
                    <p>{order_detail?.details?.customer_info.company_name}</p>
                  </div>
                )}
                {order_detail?.details?.shipping_details.apartment && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Appartment </p>:
                    <p>{order_detail?.details?.shipping_details.apartment}</p>
                  </div>
                )}
                {order_detail?.details?.shipping_details.city && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> City </p>:
                    <p>{order_detail?.details?.shipping_details.city}</p>
                  </div>
                )}
                {order_detail?.details?.shipping_details.state && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> State </p>:
                    <p>{order_detail?.details?.shipping_details.state}</p>
                  </div>
                )}
                {order_detail?.details?.shipping_details.country && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Country </p>:
                    <p>{order_detail?.details?.shipping_details.country}</p>
                  </div>
                )}
                {order_detail?.details?.shipping_details.zipcode && (
                  <div className="flex gap-2 my-2">
                    <p className="min-w-28 font-medium"> Zip Code </p>:
                    <p>{order_detail?.details?.shipping_details.zipcode}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(order_detail?.gift_note?.to ||
            order_detail?.gift_note?.from ||
            order_detail?.gift_note?.message) && (
            <div className="h-auto">
              <p className="font-semibold text-lg text-gray-500 my-1 mt-3 md:my-0">
                Gift Note / Message Information
              </p>
              {order_detail?.gift_note?.to && (
                <div className="flex gap-2 my-2">
                  <p className="min-w-24 font-medium">To </p>:
                  <p>{order_detail?.gift_note?.to}</p>
                </div>
              )}
              {order_detail?.gift_note?.from && (
                <div className="flex gap-2 my-2">
                  <p className="min-w-24 font-medium">From </p>:
                  <p>{order_detail?.gift_note?.from}</p>
                </div>
              )}
              {order_detail?.gift_note?.message && (
                <div className="flex gap-2 my-2">
                  <p className="min-w-24 font-medium">Message </p>:
                  <p
                    style={{
                      wordBreak: "break-word",
                      overflowWrap: "anywhere",
                    }}
                  >
                    {order_detail?.gift_note?.message}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <p className="font-semibold text-lg text-gray-500 my-1 mt-3 md:my-0">
          {" "}
          Product Details{" "}
        </p>
        <ListComp header={["image", "Products", "quantity", "amount"]}>
          {order_detail?.cart?.map((item: any, index: any) => {
            return(<tr key={index}>
              <td className="p-3  ">
                {item.product_type === "gift-card" ? (<img className="h-20 w-24" src={GiftImage} alt="" />) :  (<img className="h-20 w-24" src={item?.item?.image} alt="" />)}
              </td>
              <td>
                
                  <div className="text-start">
                    <div>{item?.item?.name}</div>
                    <div className="w-fit">
                      {item?.product_type && (
                        <p>Product type : {item?.product_type}</p>
                      )}
                      {item?.item?.kit && <p>Kit name : {item?.item?.kit}</p>}
                      {item?.subitem && <p>SubItem : {item?.subitem?.name}</p>}
                      {(item?.product_type === "variable" || item?.product_type === "kit")  && <p className="table_listing">Choice Products: <ul >
                        {item?.choice_products?.map((elm : any, index: any) => {
                          return(<li>
                            <span>Cookie {index + 1} : -</span><br/>
                            {elm?.name}
                          </li>)
                        })}
                      </ul></p>}
                    </div>
                  </div>
             
              </td>
              <td>{item?.quantity}</td>
              <td>
                {item?.amount ? (Number(item?.amount) / 100).toFixed(2) : "-"}
              </td>
            </tr>)
          })}
        </ListComp>
      </div>
    </div>
  );
};

export default ViewOrder;
