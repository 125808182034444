/* eslint-disable */
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import { IToggleWarnModalTypes } from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  deleteFeedbackContaint,
  initialPage,
} from "../../../../utils/staticdata";
import {
  delete_feedback,
  feedback_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setFeedbacklist } from "../../../../../redux/actions/action";
import List from "./list";

const Feedback = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [id, setid] = useState("");
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === feedback_lists.url) {
          if (receivedMessage?.response?.status === 200)
            dispatch(setFeedbacklist(receivedMessage?.response?.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setFeedbacklist(initialPage));
        }
        if (receivedMessage?.url === delete_feedback.url) {
          if (receivedMessage?.response?.status === 200) {
            send(feedback_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = feedback_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      feedback_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {


    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }
  };

  const deleteFeedback = () => {
    if (isConnect) {
      const message = { ...delete_feedback, request: { feedback_id: id } };
      send(message);
    }
  };

  return (
    <TableLayout isAdd={false} title={"Feedback"} payload={feedback_lists}>
      <List toggleWarn={toggleWarn} />

      <WarnPopup
        onSubmit={deleteFeedback}
        containt={deleteFeedbackContaint}
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default Feedback;
