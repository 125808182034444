/* eslint-disable */
import { useDispatch } from "react-redux";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import {
  IAddCouponDetails,
  ICouponDetails,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../utils/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  add_coupon,
  coupon_lists,
  delete_coupon,
  edit_coupon,
} from "../../../api/websocket/payload";
import TableLayout from "../../components/TableLayout";
import List from "./list";
import WarnPopup from "../../components/popup/WarnPopup";
import {
  deleteCouponContaint,
  editStatusContaint,
} from "../../../utils/staticdata";
import Form from "./form";
import { setCouponlist } from "../../../../redux/actions/action";
const CouponManagent = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [EditStatus, setEditStatus] = useState<IAddCouponDetails>();
  const [id, setid] = useState("");
  const [editData, setEditData] = useState<any>();
  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        coupon_id: item?.id,
        name: item?.name,
        description: item?.description,
        is_active: e.target.checked ? "1" : "0",
      };

      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const deleteCoupon = () => {
    if (isConnect) {
      const message = { ...delete_coupon, request: { coupon_id: id } };
      send(message);
    }
  };

  const editCoupon = () => {
    const payload = { ...edit_coupon, request: EditStatus };
    send(payload);
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: ICouponDetails, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage?.url === coupon_lists.url) {
        dispatch(setCouponlist(receivedMessage?.response?.data));
      }

      if (receivedMessage?.url === add_coupon.url) {
        if (receivedMessage?.response?.status === 200) {
          send(coupon_lists)
          toast.success(receivedMessage?.response?.msg);

        }
        toggleModal("hide");
      }
      if (receivedMessage?.url === edit_coupon.url) {
        if (receivedMessage?.response?.status === 200) {
          send(coupon_lists);
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === delete_coupon.url) {
        if (receivedMessage?.response?.status === 200) {
          send(coupon_lists);
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = coupon_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      coupon_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
      };
    };
  }, []);

  return (
    <TableLayout
      isFilter
      isAdd
      payload={coupon_lists}
      handleOpen={() => toggleModal("add")}
      title={"Coupon"}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteCoupon : editCoupon}
        containt={
          showWarn === "delete" ? deleteCouponContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
    </TableLayout>
  );
};

export default CouponManagent;
