/* eslint-disable */
import  { useEffect, useState } from "react";
import FormLayout from "../../../components/FormLayout";
import { InfoPageForm, pageDropdownType } from "../../../../utils/staticdata";
import TextEditor from "../../../components/input/TextEditor";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../../components/input/InputField";
import { IPages } from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  add_page,
  edit_page,
  page_detail,
} from "../../../../api/websocket/payload";
import DropdownMenu from "../../../components/DropdownMenu";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { INFO_PAGES_URL } from "../../../../constant/url";
import { toast } from "react-toastify";

const AddPage = () => {
  const { control, reset, handleSubmit, register, setValue ,formState:{errors}} =
    useForm<IPages>();
  const { send, receivedMessage, isConnect } = useWebSocket();
  const [isLable, setisLabel] = useState('Select Page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === "page_add") {
          if(receivedMessage?.response?.status===200)
            toast.success(receivedMessage?.response?.msg);

          navigate(INFO_PAGES_URL);
        }
        if (receivedMessage?.url === "page_get") {
          reset(receivedMessage?.response?.data);
          setisLabel(receivedMessage?.response?.data.type)
        }
        if (receivedMessage?.url === "page_update") {
          if(receivedMessage?.response?.status===200)
            toast.success(receivedMessage?.response?.msg);
        }
      }
    }
  }, [receivedMessage, dispatch]);

  useEffect(() => {
  if(param.id!=='') { if (location.pathname.split("/")[3] === "update-page") {
      const message = { ...page_detail, request: { page_id: param.id } };

      send(message);
    }}
  }, [isConnect, receivedMessage?.url]);

  const onSubmit = (data: IPages) => {
    let payload;
    if (location.pathname.split("/")[3] === "add-page") {
      payload = {
        ...add_page,
        request: data,
      };
      send(payload);
    }
    if (location.pathname.split("/")[3] === "update-page") {  
      payload = {
        ...edit_page,
        request: {
          page_id: param.id,
          title: data.title,
          type: data.type,
          description:data.description,
          is_active: data?.is_active?'1':'0',
        },
      };

      send(payload);
    }

  };
  return (
    <FormLayout
      containt={InfoPageForm}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    >
      <div className="lg:flex w-full gap-4">
        <InputField
          name={"Title"}
          placeholder={"Enter title"}
          className="my-2 w-full"
          inputClassName="h-9"
          required
              error={errors.title}
              register={{
                ...register("title", {
                  required: "title is required",
                  maxLength: {
                    value: 256,
                    message: "title cannot exceed 256 characters",
                  },
                }),
              }}
        />
        <div className="lg:w-1/3 mt-1">
          <label
            htmlFor=""
            className="font-medium text-gray-600 text-sm my-[2px]"
          >
            Type
          </label>
          <DropdownMenu
            label={isLable}
            setLabel={setisLabel}
            handleChange={() => {}}
            setValue={setValue}
            data={pageDropdownType}
            name={"type"}
          />
        </div>
      </div>
      <div className="lg:h-80 h-96 mb-24 lg:mb-0">
        <label
          htmlFor="content"
          className="font-medium text-gray-600 text-sm my-[2px]"
        >
          Content
        </label>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{
            required: "Description is required",
            minLength: {
              value: 10,
              message: "description must be at least 10 characters long"
            },
           
            validate: (value) => value.trim().length > 0 || "Description cannot be empty"
          }}
          render={({ field }) => <TextEditor field={field} errors={errors.description}/>}
        />
       
      </div>
      
    </FormLayout>
  );
};

export default AddPage;
