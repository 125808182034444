/*eslint-disable*/
import React, { useState, ChangeEvent, useEffect } from "react";
import { postData } from "../../api/rest/fetchData";
import { FILE_UPLOAD_URL } from "../../constant/url";
import { usePosterReducers } from "../../../redux/getdata/usePostReducer";
import { RxCross2 } from "react-icons/rx";

interface IUploadfilesTypes {
  setValue: any; // react-hook-form's setValue function
  singleUpload: boolean; // New prop to handle single or multiple uploads
  initialImageUrls?: string[]; // Initial image URLs for edit mode
  error?: any; // Error
  name?: string; //
  title?: string; //
  clearError?:any
  required?: boolean; //
}

const UploadImage: React.FC<IUploadfilesTypes> = ({
  setValue,
  singleUpload,
  initialImageUrls = [],
  error, name, title,clearError,required
}) => {
  const [message, setMessage] = useState<string | undefined>();
  const [Loading, setLoading] = useState(false);
  const { accessToken } = usePosterReducers();
  const [files, setFiles] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    // Filter out any empty strings from initialImageUrls
    const filteredInitialUrls = initialImageUrls.filter(url => url?.trim() !== "");
    // If initialImageUrls are provided, set them as initial value in the form and state
    if (filteredInitialUrls.length > 0) {
      setValue(singleUpload ? (name ? name : "image") : "images", filteredInitialUrls?.join(","));
      setImageUrls(filteredInitialUrls);
    }
  }, [ setValue, singleUpload, initialImageUrls]);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(undefined);
    const selectedFiles = Array.from(e.target.files || []);
    const validImageTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
    ];
    const validFiles = selectedFiles?.filter((file) =>
      validImageTypes.includes(file.type)
    );

    if (validFiles.length < selectedFiles.length) {
      setMessage("Only images are accepted");
    }

    if (validFiles.length > 0) {
      const previewUrls = validFiles?.map((file) => URL.createObjectURL(file));

      if (singleUpload) {
        setFiles(validFiles);
        setImageUrls(previewUrls);
        setValue((name ? name : "image"), previewUrls[0]);
        uploadFiles(validFiles, previewUrls);
      } else {
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        setImageUrls((prevUrls) => [...prevUrls, ...previewUrls]);
        setValue("images", [...imageUrls, ...previewUrls].join(","));
        uploadFiles(validFiles, previewUrls);
      }
    }
  };

  const uploadFiles = (filesToUpload: File[], previewUrls: string[]) => {
    setLoading(true);
    const uploadPromises = filesToUpload?.map((file, index) => {
      const formData = new FormData();
      formData.append("file", file, file.name);

      return postData(
        FILE_UPLOAD_URL,
        formData,
        accessToken,
        "multipart/form-data"
      )
        .then((response) => ({
          previewUrl: previewUrls[index],
          url: response.data.url.file_url,
          
        }))
        .catch((error) => {
          console.error("Error uploading the file:", error);
          setMessage("Upload failed.");
          return null;
        });
    });

    Promise.all(uploadPromises).then((results) => {
      const uploadedUrls = results?.filter((result) => result !== null)?.map((result) => result!.url);
      setImageUrls((prevUrls) => {
        const newUrls = singleUpload
          ? uploadedUrls
          : prevUrls?.map((url) => {
            const result = results.find((r) => r && r.previewUrl === url);
            return result ? result.url : url;
          });
        singleUpload
          ? setValue((name ? name : "image"), newUrls[0])
          : setValue("images", newUrls?.join(","));
          clearError(name)
        return newUrls;
      });


      setLoading(false);
    });
  };

  const removeImage = (index: number) => {
    const updatedFiles = files?.filter((_, i) => i !== index);
    const updatedImageUrls = imageUrls?.filter((_, i) => i !== index);

    setFiles(updatedFiles);
    setImageUrls(updatedImageUrls);

    singleUpload
      ? setValue((name ? name : "image"), "")
      : setValue("images", updatedImageUrls?.join(","));
  };

  return (
    <div>
      <p className="font-medium text-gray-600 text-sm my-[1px]">
        Upload {singleUpload ? (title ? title : "image") : "images"}{required&&<span className="text-red-500"> *</span>}
      </p>
      <div className="flex py-1 items-center">
        <div className="rounded-lg bg-gray-50 w-full">
          <div className="m-4 gap-4">
            <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
              {message}
            </span>
            <div className="flex items-center justify-center w-full">
              {!singleUpload || imageUrls.length === 0 ? (
                <label className="flex cursor-pointer flex-col w-full h-32 border-2 rounded-md border-dashed hover:bg-gray-100 hover:border-gray-300">
                  <div className="flex flex-col items-center justify-center pt-7">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                      {singleUpload ? "Select a photo" : "Select photos"}
                    </p>
                  </div>
                  <input
                    type="file"
                    onChange={handleFile}
                    className="opacity-0"
                    accept=".png, .jpg, .jpeg, .webp"
                    multiple={!singleUpload}
                  />
                </label>
              ) : null}
            </div>
            <div className="flex w-full justify-center gap-2 mt-2 flex-wrap">
              {Array.isArray(imageUrls) &&
                imageUrls?.length > 0 &&
                imageUrls?.map((url, index) => (
                  <div key={index} className="relative">
                    {Loading && index === imageUrls.length - 1 ? (
                      <div className="spinner absolute right-1"></div>
                    ) : (
                      <i
                        onClick={() => removeImage(index)}
                        className="absolute right-1 text-red-500 cursor-pointer"
                      >
                        <RxCross2 />
                      </i>
                    )}
                    <img
                      className="h-48 rounded-md"
                      src={url}
                      alt={`Selected ${index}`}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {error && (
        <p className="text-xs mx-1 text-red-500">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default UploadImage;
