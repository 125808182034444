import React from "react";

interface ICoutCardType{
    className: string;
    title?:string;
    count?:string|number|null;
}

const CoutCard:React.FC<ICoutCardType> = (prop) => {
  return (
    <div className={`flex justify-center items-center w-[5.5rem] h-[5.5rem]  rounded-lg bg-gradient-to-tl ${prop.className}  shadow-soft-2xl`}>
      <div className="text-center leading-6 text-white">
        <p>{prop.title}</p>
        <p>{prop.count}</p>
      </div>
    </div>
  );
};

export default CoutCard;
