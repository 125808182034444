import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import {
  subscription_email_lists,
} from "../../../../api/websocket/payload";

interface IList {
 
}

const List: React.FunctionComponent<IList> = (props) => {
  const { subscription_mail_list } = usePosterReducers();

  const header = ["email", "Created At" ];
  return (
    <ListComp payLoad={subscription_email_lists} pages={subscription_mail_list?.pagination} header={header}>
      {subscription_mail_list?.data?.length !== 0 &&
        subscription_mail_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td className="py-3">{item.email}</td>

            <td>{dateFormat(item.created_at)}</td>

            
          </tr>
        ))}
    </ListComp>
  );
};

export default List;
