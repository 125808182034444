/* eslint-disable */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import List from "./list";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import TableLayout from "../../components/TableLayout";
import {   payment_lists } from "../../../api/websocket/payload";
import {  setPaymentList} from "../../../../redux/actions/action";


const PaymentManagement = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === payment_lists.url) {
          dispatch(setPaymentList(receivedMessage?.response?.data));
        }
      }
    }

 
  }, [receivedMessage, isConnect]);

  useEffect(() => {
    if (isConnect) {
      const message = payment_lists
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      payment_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
      };
    };
  }, [])

  return (
    <TableLayout
      isFilter
      isAdd={false}
      title={"Payment List"}
      payload={payment_lists}
    >
      <List />
    </TableLayout>
  );
};

export default PaymentManagement;
