/* eslint-disable */
import { useDispatch } from 'react-redux';
import Form from './Form'
import { useWebSocket } from '../../../../api/websocket/WebSocketContext';
import { useEffect } from 'react';
import { get_setting, setting_update } from '../../../../api/websocket/payload';
import { setFooter } from '../../../../../redux/actions/action';
import { toast } from 'react-toastify';


const Footer = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === setting_update.url) {
          if(receivedMessage?.response?.status===200)
            toast.success(receivedMessage?.response?.msg);
          
        }
        if (receivedMessage?.url === get_setting.url) {
          dispatch(setFooter(receivedMessage?.response?.data));
        }
      }
    }

   
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = get_setting;
      send(message);
    }
  }, [isConnect, receivedMessage?.url]);
  return (
    <><Form/></>
  )
}

export default Footer