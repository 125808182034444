/* eslint-disable */
import  { useEffect } from "react";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProductdetail } from "../../../../../redux/actions/action";
import { product_details } from "../../../../api/websocket/payload";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
const ViewProduct = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const { product_detail } = usePosterReducers();

  const param = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === "product_get") {
          dispatch(setProductdetail(receivedMessage?.response?.data));
        }
      }
    }
  }, [receivedMessage, dispatch]);

  useEffect(() => {
    if (isConnect) {
      const message2 = {
        ...product_details,
        request: { product_id: param.id },
      };

      send(message2);
    }
  }, [isConnect, receivedMessage?.url]);
  return (
    <div className="p-2 px-3 overflow-auto h-full">
      <p className="font-semibold text-lg text-gray-500 my-2 md:my-0">
        Product Details
      </p>

      <div className="md:flex gap-4 py-2">
        <img
          src={product_detail?.image}
          className="h-72 w-96 md:mb-0 mb-2 border rounded-md"
          width="250"
          alt=""
        />
        <div className="mt-3 md:mt-0">
          <div className="flex gap-2 mb-2 text-sm">
            <p className="min-w-44 font-medium">Product Name </p>:
            <p>{product_detail?.name}</p>
          </div>
          {product_detail?.regular_price&&<div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Price </p>:
            <p>{Number(product_detail?.regular_price) / 100}</p>
          </div>}
          {product_detail?.sale_price && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Sale Price </p>:
              <p>{Number(product_detail?.sale_price) / 100}</p>
            </div>
          )}
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Weight </p>:
            <p>{product_detail?.weight}</p>
          </div>
          {product_detail?.category && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Category </p>:
              <p>{product_detail?.category}</p>
            </div>
          )}
          {/* {product_detail?.variant && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Variant </p>:
              <p>{product_detail?.variant}</p>
            </div>
          )} */}
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Product Short Description </p>:
            <p>{product_detail?.short_description}</p>
          </div>
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Product Description </p>:
            <p>{product_detail?.description}</p>
          </div>
          {product_detail?.ingredients && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Product Ingredients </p>:
              <p>{product_detail?.ingredients}</p>
            </div>
          )}
          {product_detail?.nutrition_facts && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Product Nutrition Facts </p>:
              <p>{product_detail?.nutrition_facts}</p>
            </div>
          )}
          {product_detail?.dimension?.length ||product_detail?.dimension?.height||product_detail?.dimension?.width && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Dimensions </p>:
              <p>
                {product_detail?.dimension.length} X{" "}
                {product_detail?.dimension.width} X{" "}
                {product_detail?.dimension.height}
              </p>
            </div>
          )}

          {product_detail?.purchase_note && (
            <div className="flex gap-2 my-2 text-sm">
              <p className="min-w-44 font-medium">Purchase Note </p>:
              <p>{product_detail?.purchase_note}</p>
            </div>
          )}
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Stock Status </p>:
            <p>{product_detail?.in_stock ? "Active" : "Inactive"}</p>
          </div>
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Active Status </p>:
            <p>{product_detail?.is_active ? "Active" : "Inactive"}</p>
          </div>
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Shipping Status </p>:
            <p>{product_detail?.has_shipping ? "Active" : "Inactive"}</p>
          </div>
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Exclude From Pack Status </p>:
            <p>{product_detail?.exclude_from_pack_kit ? "Active" : "Inactive"}</p>
          </div>
          <div className="flex gap-2 my-2 text-sm">
            <p className="min-w-44 font-medium">Subscription Status </p>:
            <p>{product_detail?.has_subscription ? "Active" : "Inactive"}</p>
          </div>
        </div>
      </div>
    </div>

    // <></>
  );
};

export default ViewProduct;
