import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Alert = () => {
  return (
    <ToastContainer
    position="top-center"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    
/>
  )
}

export default Alert