/* eslint-disable */
import { useEffect, useState } from "react";
import InputField from "../../../components/input/InputField";
import { IToggleModalTypes, IVariantTypes } from "../../../../utils/types";
import { Controller, useForm } from "react-hook-form";
import FormPopup from "../../../components/popup/FormPopup";
// import Dropdown from "../../../components/dropdown";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { RxCross2 } from "react-icons/rx";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { add_variant, edit_variant } from "../../../../api/websocket/payload";
import Dropdown from "../../../components/DropdownN";
import { toast } from "react-toastify";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IVariantTypes;
  isEditable: boolean;
}

const Form = (prop: IForm) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IVariantTypes>({
    defaultValues: {
      name: "",
      product_limit: "",
      quantity: "",
      price: "",
      categories_data: [],
    },
  });

  const { category_list } = usePosterReducers();
  const filter=category_list?.data?.filter(
    (item: any) =>
      item.type === 'single'
  );
  const { send } = useWebSocket();
  const quantity = watch("quantity");
  const product_limit = watch("product_limit");
  const categories = watch("categories_data", []);
  const [divisibleNumbers, setDivisibleNumbers] = useState<
    { value: number; label: number }[]
  >([]);
 
  const [dropdownsState, setDropdownsState] = useState<number[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [isValidProductLimit, setIsValidProductLimit] =
    useState<boolean>(false); // New state for valid product limit


  useEffect(() => {
    if (prop.showModal === "edit") {
      reset({...prop.editData,price:(Number(prop.editData.price)/100).toString()}); // Resets the form values to prop.editData
      setValue("categories_data", prop.editData.categories_data);
      setDropdownsState(prop.editData.categories_data?.map((_elm) => 0));
    } else {
      reset({
        name: "",
        product_limit: "",
        quantity: "",
        price: "",
        categories_data: [],
      });
    }
  }, [prop.showModal, reset, prop.editData]);


  function addCount() {
    if (
      categories.length < Number(product_limit) &&
      totalQuantity < Number(quantity)
    ) {
      const dataArray = new Array(dropdownsState.length + 1).fill(null);
      setDropdownsState(dataArray);
    }
  }

  function removeDropdown(index: number) {
    const removedQuantity = Number(categories[index]?.quantity) || 0;
    const newDropdownsState = dropdownsState?.filter((_, i) => i !== index);
    setDropdownsState(newDropdownsState);

    const newCategories = categories?.filter((_: any, i: any) => i !== index);
    setValue("categories_data", newCategories);

    // Update the total quantity after removing a dropdown
    setTotalQuantity(totalQuantity - removedQuantity);
    // setTimeout(() => {
    //   reset({...prop.editData,categories_data: newCategories,price:watch("price")});
    // }, 500);
  };

  useEffect(() => {
    if (Array.isArray(categories)) {
      const sum = categories.reduce(
        (acc: number, current: any) => acc + (parseInt(current.quantity) || 0),
        0
      );
      setTotalQuantity(sum);
    }
  }, [categories]);

  useEffect(() => {
    return () => {
      setDropdownsState([]);
    };
  }, [prop.showModal]);

  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Variant`,
    submit: "Submit",
    close: "Cancel",
  };

  const extractedNames = filter?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (quantity) {
      calculateDivisibles(Number(quantity));
      calculateQuantities(Number(quantity)); // Calculate quantities based on the quantity entered
    } else {
      setDivisibleNumbers([]);
    };
  }, [quantity]);

  const calculateDivisibles = (quantity: number) => {
    const divisibles = [];
    for (let i = 1; i <= 9; i++) {
      if (quantity % i === 0) {
        divisibles.push({ label: i, value: i });
      }
    }
    setDivisibleNumbers(divisibles);
  };

  const calculateQuantities = (quantityValue: number) => {
    const quantities = [];
    for (let i = 1; i <= quantityValue; i++) {
      quantities.push({ label: i, value: i });
    }
    
  };

  const handleQuantityChange = (index: number, value: number) => {
    const newCategories = [...categories];
    const oldQuantity = Number(newCategories[index]?.quantity) || 0;
    newCategories[index] = {
      ...newCategories[index],
      quantity: value,
    };
    setValue("categories_data", newCategories);

    // Update total quantity after a change in any dropdown
    const sum = totalQuantity - oldQuantity + value;
    setTotalQuantity(sum);
  };

  const getAvailableQuantities = (index: number) => {
    const remainingQuantity =
      Number(quantity) -
      totalQuantity +
      (Number(categories[index]?.quantity) || 0);
    return Array.from({ length: remainingQuantity }, (_, i) => ({
      value: i + 1,
      label: i + 1,
    }));
  };

  const onSubmit = (data: IVariantTypes) => {
    if (prop.showModal === "add") {
      if(dropdownsState?.length > 0) {
        const Data = {
          ...data,
          category_ids: data?.categories_data
            ?.map((item: any) => item.category_id)?.join(","),
          price: Number(data?.price) * 100,
        };
        const payload = { ...add_variant, request: Data };
        send(payload);
      } else {
        toast.error("Please select category !")
      }
    }

    if (prop.showModal === "edit") {
      if(dropdownsState?.length > 0) {
        const Data = {
          variant_id: prop.editData.id,
          name: data.name,
          description: data.description,
          product_limit: data.product_limit,
          quantity: data.quantity,
          price: Number(data?.price) * 100,
          category_ids: data?.categories_data
            ?.map((item: any) => item.category_id)?.join(","),
          categories_data:  data?.categories_data,
          is_active: data.is_active ? "1" : "0",
        };

        const payload = { ...edit_variant, request: Data };

        send(payload);
      } else {
        toast.error("Please select category !")
      }
    }
  };

  // UseEffect to validate if product_limit is valid whenever quantity or product_limit changes
  useEffect(() => {
    if (product_limit && divisibleNumbers.length > 0) {
      const validProductLimit = divisibleNumbers.some(
        (item) => item.value === Number(product_limit)
      );
      setIsValidProductLimit(validProductLimit);
    } else {
      setIsValidProductLimit(false);
    }
  }, [product_limit, divisibleNumbers]);

  useEffect(() => {
    if (prop.showModal === "edit") {
        if(prop.editData.quantity !== quantity || prop.editData.product_limit !== product_limit) {
            setDropdownsState([]);
            setValue("categories_data", []);
            setValue("price", watch("price"));
            setValue("product_limit", watch("product_limit"));
            setTotalQuantity(0);
            // reset({
            //   categories_data: [],
            // });
            // reset({categories_data: watch("categories_data")});
            // setTimeout(() => {
              reset({...prop.editData,categories_data: watch("categories_data"), price: watch("price"), product_limit: watch("product_limit"),quantity: watch("quantity")});
            // }, 500);
        };
    } else {
      setDropdownsState([]);
      setValue("categories_data", []);
      setTotalQuantity(0);
      // reset({
      //   categories_data: [],
      // });
    }
  }, [quantity, product_limit, setValue]);

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
        onSubmit={onSubmit}
      >
        <InputField
          name={"Variant Name"}
          required
          register={register("name", {
            required: "variant name is required",
            maxLength: {
              value: 256,
              message: "Variant name cannot exceed 256 characters",
            },
          })}
          error={errors.name}
          placeholder={"Enter Variant"}
          inputClassName="h-9"
          className=""
        />
        
        {/* {prop.showModal !== "edit" && ( */}
          <>
            <InputField
              name={"Quantity"}
              placeholder={"Enter Quantity"}
              inputClassName="h-9"
              className=""
              required
              register={{
                ...register("quantity", {
                  required: "Quantity is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Quantity must be a number",
                  },
                }),
              }}
              error={errors.quantity}
            />
            <InputField
              name={"Product Limit"}
              required
              error={errors.product_limit}
              register={register("product_limit", {
                required: "Product limit is required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Product limit must be a number",
                },
                validate: (value) =>
                  (quantity && Number(quantity) % Number(value) === 0) ||
                  `Product limit must be a factor of ${quantity}`,
              })}
              placeholder={`Enter Product Limit ${divisibleNumbers?.map(
                (item) => item.value
              )}`}
              inputClassName="h-9"
              className=""
            />

            {dropdownsState?.map((_: any, index: number) => {
              return(<div key={index} className="flex items-center gap-4 mt-2">
                <Controller
                  name={`categories_data.${index}.category_id`}
                  control={control}
                  rules={{
                    required: "Product is required",
                    validate: (value: any) =>
                      (typeof value === "string" && value.trim().length > 0) ||
                      "Product cannot be empty",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <Dropdown
                      label="Select Category"
                      options={extractedNames}
                      value={field.value}
                      onChange={(value: any) => {
                        field.onChange(value);
                        setValue(`categories_data.${index}.category_id`, value);
                      }}
                      name={field.name}
                      className="py-[0.43rem]"
                      errors={error}
                      required
                      isDynamic
                    />
                  )}
                />
                <Controller
                  name={`categories_data.${index}.quantity`}
                  control={control}
                  rules={{}}
                  render={({ field, fieldState: { error } }) => (
                    <Dropdown
                      label="Select Quantity"
                      options={getAvailableQuantities(index)} // Limit options to available quantities
                      value={field.value}
                      onChange={(newValue: any) => {
                        field.onChange(newValue);
                        setValue(`categories_data.${index}.quantity`, newValue);
                        handleQuantityChange(index, Number(newValue));
                      }}
                      isDynamic
                      name={field.name}
                      className="py-[0.43rem]"
                      errors={error}
                      required
                    />
                  )}
                />

                <button
                  type="button"
                  onClick={() => removeDropdown(index)}
                  className="h-fit w-fit text-sm font-medium border border-red-600 rounded-full text-red-600"
                >
                  <RxCross2 />
                </button>
              </div>)
            })}
            {(isValidProductLimit &&
              product_limit &&
              quantity &&
              categories.length < Number(product_limit) &&
              totalQuantity < Number(quantity)) && (
                <button
                  type="button"
                  onClick={addCount}
                  className="px-3 py-2 mt-2 text-sm font-medium text-white bg-blue-600 rounded"
                >
                  + Add Category
                </button>
              )}
            {product_limit && categories.length >= Number(product_limit) && (
              <p className="text-sm text-red-500 mt-2">
                You have reached the maximum number of categories allowed.
              </p>
            )}
            {quantity && totalQuantity >= Number(quantity) && (
              <p className="text-sm text-red-500 mt-2">
                You have reached the maximum total quantity allowed.
              </p>
            )}
          </>
        {/* // )} */}

       {/* {prop.showModal==='add' && */}
       <InputField
          name={"Price"}
          register={register("price", {
            required: "price is required",
            pattern: {
              value: /^\d+(\.\d+)?$/,
              message: "Price must be a number",
            },
          })}
          required
          inputClassName="h-8"
          error={errors.price}
          placeholder="Enter Price"
        />
        {/* } */}
        
        <InputField
          name={"Variant Description"}
          register={register("description", {
            required: "Variant description is required",
            maxLength: {
              value: 2000,
              message: "Description cannot exceed 256 characters",
            },
          })}
          required
          error={errors.description}
          placeholder="Enter Variant Description"
          useFor="textarea"
        />
        {/* {prop.showModal !== "add" && ( */}
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        {/* )} */}
      </FormPopup>
    </>
  );
};

export default Form;
