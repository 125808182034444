/*eslint-disable*/
import { useEffect, useRef, useState } from "react";
import InputField from "../../../components/input/InputField";
import { IAboutUsType } from "../../../../utils/types";
import { Controller, useForm } from "react-hook-form";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { add_about_us, edit_about_us } from "../../../../api/websocket/payload";
import UploadImage from "../../../components/UploadImage";
import FormLayout from "../../../components/FormLayout";
import {
  addStoryformContaint,
  editStoryformContaint,
} from "../../../../utils/staticdata";
import TextEditor from "../../../components/input/TextEditor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";

const AddAboutUs = () => {
  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<IAboutUsType>();
  // const [Imgurl, setImgurl] = useState<any[]>([]);
  const navigate = useNavigate();
  const { send, receivedMessage, isConnect } = useWebSocket();
  const path = useLocation();
  const { about_us_list } = usePosterReducers();
  const param = useParams();
  const url = path.pathname.split("/")[3];
  const ref = useRef<HTMLDivElement>(null);
  const detail = about_us_list.data.find((x) => x.id === param?.id);
  const [initialImageUrls, setInitialImageUrls] = useState<any[]>([]);
  const [initialImageUrls1, setInitialImageUrls1] = useState<any[]>();
  const [initialImageUrls2, setInitialImageUrls2] = useState<any[]>();
  const [initialImageUrls3, setInitialImageUrls3] = useState<any[]>();
  const [initialImageUrls4, setInitialImageUrls4] = useState<any[]>();
  const [initialImageUrls5, setInitialImageUrls5] = useState<any[]>();
  useEffect(() => {
    if (url === "edit-aboutus") {
      reset(detail); // Resets the form values to prop.editData
      setInitialImageUrls([detail?.images?.image1])
      setInitialImageUrls1([detail?.images?.image2])
      setInitialImageUrls2([detail?.images?.image3])
      setInitialImageUrls3([detail?.images?.image4])
      setInitialImageUrls4([detail?.images?.image5])
      setInitialImageUrls5([detail?.images?.image6])
    }
    if (url === "edit-aboutus") {
      reset(); // Resets the form values to prop.editData
    }
  }, [reset]);

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === add_about_us.url) {
          if (receivedMessage?.response?.status === 200)
            toast.success(receivedMessage.response.msg);
          navigate("/content-management/about-us");
        }
        if (receivedMessage.url === edit_about_us.url) {
          if (receivedMessage?.response?.status === 200)
            navigate("/content-management/about-us");
        }
      }
    }
  }, [receivedMessage?.url]);


 

  const onSubmit = (data: IAboutUsType) => {
    
    if ("images" in data && data.images) {
      if (!data.images.image1 ) {
        setError("images.image1", {
          type: "manual",
          message: "Home Page Image is required",
        });
        return;
      }
      if (!data.images.image2) {
        setError("images.image2", {
          type: "manual",
          message: "Story Image 1 is required",
        });
        return;
      }
      if (!data.images.image3) {
        setError("images.image3", {
          type: "manual",
          message: "Story Image 2 is required",
        });
        return;
      }
      if (!data.images.image4) {
        setError("images.image4", {
          type: "manual",
          message: " Story Image 3 is required",
        });
        return;
      }
      if (!data.images.image5) {
        setError("images.image5", {
          type: "manual",
          message: "Story Description Image 1 is required",
        });
        return;
      }
      if (!data.images.image6) {
        setError("images.image6", {
          type: "manual",
          message: "Story Description Image 2 is required",
        });
        return;
      }
      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setError("images.image1", {
        type: "manual",
        message: "Home Page Image is required",
      });

      setError("images.image2", {
        type: "manual",
        message: "Story Image 1 is required",
      });

      setError("images.image3", {
        type: "manual",
        message: "Story Image 2 is required",
      });

      setError("images.image4", {
        type: "manual",
        message: " Story Image 3 is required",
      });

      setError("images.image5", {
        type: "manual",
        message: "Story Description Image 1 is required",
      });

      setError("images.image6", {
        type: "manual",
        message: "Story Description Image 2 is required",
      });
      if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (url === "add-aboutus") {
      const payload = {
        ...add_about_us,
        request: data,
      };
      send(payload);
    }
    if (url === "edit-aboutus") {
      const Data = {
        story_id: param?.id,
        title: data.title,
        description1: data.description1,
        description2: data.description2,
        description3: data.description3,
        is_active: data.is_active ? "1" : "0",
        images: data.images,
      };
      const payload = { ...edit_about_us, request: Data };

      send(payload);
    }
  };


  

  return (
    <>
      <FormLayout
        containt={
          url === "add-aboutus" ? addStoryformContaint : editStoryformContaint
        }
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      >
        <div className="mb-4" ref={ref}>
          <div className="grid grid-cols-3 gap-4">
            <UploadImage
              required
              name={"images.image1"}
              initialImageUrls={initialImageUrls}
              setValue={setValue}
              singleUpload
              title="Home Page Image"
              error={errors.images?.image1}
              clearError={clearErrors}
            />
            <UploadImage
              required
              name={"images.image2"}
              initialImageUrls={initialImageUrls1}
              setValue={setValue}
              singleUpload
              title="Story Image 1"
              error={errors.images?.image2}
              clearError={clearErrors}
            />
            <UploadImage
              required
              name={"images.image3"}
              initialImageUrls={initialImageUrls2}
              setValue={setValue}
              singleUpload
              title="Story Image 2"
              error={errors.images?.image3}
              clearError={clearErrors}
            />
            <UploadImage
              required
              name={"images.image4"}
              initialImageUrls={initialImageUrls3}
              setValue={setValue}
              singleUpload
              title="Story Image 3"
              error={errors.images?.image4}
              clearError={clearErrors}
            />
            <UploadImage
              required
              name={"images.image5"}
              initialImageUrls={initialImageUrls4}
              setValue={setValue}
              singleUpload
              title="Story Description Image 1"
              error={errors.images?.image5}
              clearError={clearErrors}
            />
            <UploadImage
              required
              name={"images.image6"}
              initialImageUrls={initialImageUrls5}
              setValue={setValue}
              singleUpload
              title="Story Description Image 2"
              error={errors.images?.image6}
              clearError={clearErrors}
            />
          </div>
        </div>
        <InputField
          name={"Story Title"}
          placeholder={"Enter Story Title"}
          inputClassName="h-9 "
          className="w-full my-4 lg:my-2 "
          required
          error={errors.title}
          register={{
            ...register("title", {
              required: "story title is required",
              maxLength: {
                value: 256,
                message: "story title cannot exceed 256 characters",
              },
            }),
          }}
        />

        <div className="flex gap-4 mb-14 ">
          <div className="lg:h-80 h-96 mb-24 lg:mb-0 Description_cols">
            <label
              htmlFor="content"
              className="font-medium text-gray-600 text-sm my-[2px]"
            >
              Home Page Description <span className="text-red-500"> *</span>
            </label>
            <Controller
              name="description3"
              control={control}
              defaultValue=""
              rules={{
                required: "Description3 is required",
                minLength: {
                  value: 10,
                  message: "Description3 must be at least 10 characters long",
                },

                validate: (value) =>
                  value.trim().length > 0 || "Description3 cannot be empty",
              }}
              render={({ field }) => (
                <TextEditor field={field} errors={errors.description2} />
              )}
            />
          </div>
          <div className="lg:h-80 h-96 mb-24 lg:mb-0 Description_cols">
            <label
              htmlFor="content"
              className="font-medium text-gray-600 text-sm my-[2px]"
            >
              Story Description 1<span className="text-red-500"> *</span>
            </label>
            <Controller
              name="description1"
              control={control}
              defaultValue=""
              rules={{
                required: "Description2 is required",
                minLength: {
                  value: 10,
                  message: "Description2 must be at least 10 characters long",
                },

                validate: (value) =>
                  value.trim().length > 0 || "Description2 cannot be empty",
              }}
              render={({ field }) => (
                <TextEditor field={field} errors={errors.description2} />
              )}
            />
          </div>
          <div className="lg:h-80 h-96 mb-24 lg:mb-0 Description_cols">
            <label
              htmlFor="content"
              className="font-medium text-gray-600 text-sm my-[2px]"
            >
              Story Description 2<span className="text-red-500"> *</span>
            </label>
            <Controller
              name="description2"
              control={control}
              defaultValue=""
              rules={{
                required: "Description2 is required",
                minLength: {
                  value: 10,
                  message: "Description2 must be at least 10 characters long",
                },

                validate: (value) =>
                  value.trim().length > 0 || "Description2 cannot be empty",
              }}
              render={({ field }) => (
                <TextEditor field={field} errors={errors.description2} />
              )}
            />
          </div>
        </div>
      </FormLayout>
    </>
  );
};

export default AddAboutUs;
