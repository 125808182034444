/*eslint-disable*/
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { useParams } from "react-router-dom";
import { dateFormat } from "../../../../utils/common";

const ViewStory = () => {
  const param = useParams();
  const { about_us_list } = usePosterReducers();
  const detail = about_us_list?.data?.find((x) => param.id === x.id);
  return (
    <div className="p-2 px-3 overflow-auto h-full w-fit">
      <p className="font-semibold text-lg text-gray-500 my-2 md:my-0">
        Your Story Details
      </p>

      <div className="mt-3 md:mt-0">
        <div className="flex gap-4 text-center   text-sm max-h-40 max-w-40">
          {Object.values(detail?.images ?? {}).length!==0&&Object.values(detail?.images ?? {}).map((item, index) => (
            <div className="min-w-full   my-6">
              <img className="h-full " src={item} alt="" />
              <p className="my-2 text-gray-600 ">{index===0?'Home Page Image':index===1?'Story Image 1':index===2?'Story Image 2':index===3?'Story Image 3':index===4?'Story Description Image 1':'Story Description Image 2'}</p>
            </div>
          ))}
        </div>
        <div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Title </p>:
          <p>{detail?.title ? detail.title : ""}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Home Page Description </p>:
          <p dangerouslySetInnerHTML={{__html:detail?.description1 ? detail.description1 : ""}}>{}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Story Description 1  </p>:
          <p dangerouslySetInnerHTML={{__html:detail?.description1 ? detail.description1 : ""}}>{}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Story Description 2  </p>:
          <p dangerouslySetInnerHTML={{__html:detail?.description2 ? detail.description2 : ""}}>{}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Created Date </p>:
          <p>{dateFormat(detail?.created_at ? detail.created_at : "")}</p>
        </div>

        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium"> Status </p>:
          <p>{detail?.is_active ? "Active" : "Inactive"}</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStory;
