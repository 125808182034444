/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  ICategoryTypes,
  ICommunityEventDetails,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import {
  deleteCommunityEventContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  add_community_event,
  community_event_lists,
  delete_community_event,
  edit_community_event,
} from "../../../../api/websocket/payload";
import Form from "./form";
import { setCommunityEventlist } from "../../../../../redux/actions/action";

const CommunityEvent = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [EditStatus, setEditStatus] = useState<ICategoryTypes>();
  const [id, setid] = useState("");
  const [editData, setEditData] = useState<any>();
  const toggleWarn = (e: any, item: any, type: string) => {

    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        event_id: item?.id,
        title: item?.title,
        description: item?.description,
        country: item?.country,
        state: item?.state,
        image: item?.image,
        address: item?.address,
        start_time: item?.start_time,
        end_time: item?.end_time,
        is_active: e.target.checked ? "1" : "0",
      };

      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const deleteCommunityevent = () => {
    if (isConnect) {
      const message = { ...delete_community_event, request: { event_id: id } };
      send(message);
    }
  };

  const editCummunityevent = () => {
    const payload = { ...edit_community_event, request: EditStatus };
    send(payload);
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: ICommunityEventDetails, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage?.url === community_event_lists.url) {
        if (receivedMessage?.response?.status === 200)
          dispatch(setCommunityEventlist(receivedMessage?.response?.data));
        if (receivedMessage?.response?.status === 400)
          dispatch(setCommunityEventlist(initialPage));
      }

      if (receivedMessage?.url === add_community_event.url) {
        if (receivedMessage?.response?.status === 200) {
          send(community_event_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
      }
      if (receivedMessage?.url === edit_community_event.url) {
        if (receivedMessage?.response?.status === 200) {
          send(community_event_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === delete_community_event.url) {
        if (receivedMessage?.response?.status === 200) {
          send(community_event_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message1 = community_event_lists;
      send(message1);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      community_event_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isAdd
      isFilter
      payload={community_event_lists}
      handleOpen={() => toggleModal("add")}
      title={"Community Event"}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />
      <WarnPopup
        onSubmit={
          showWarn === "delete" ? deleteCommunityevent : editCummunityevent
        }
        containt={
          showWarn === "delete"
            ? deleteCommunityEventContaint
            : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
    </TableLayout>
  );
};

export default CommunityEvent;
