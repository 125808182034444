import React, { useEffect } from "react";
import FormPopup from "../../../components/popup/FormPopup";
import InputField from "../../../components/input/InputField";
import { useForm } from "react-hook-form";
import { IGiftCard, IToggleModalTypes } from "../../../../utils/types";
import { add_gift_card, edit_gift_card } from "../../../../api/websocket/payload";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IGiftCard;
  isEditable: boolean;
}
const Form: React.FunctionComponent<IForm> = (prop) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<IGiftCard>();
  const { send } = useWebSocket();
  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Gift card`,
    submit: "Submit",
    close: "Cancel",
  };

  useEffect(() => {
    if (prop.showModal === "edit") {
      reset({
        ...prop.editData,
        amount: (Number(prop.editData.amount) / 100).toString(),
      }); // Resets the form values to prop.editData
    } else {
      reset({ amount: "" }); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);

  const onSubmit = (data: IGiftCard) => {
    if (prop.showModal === "add") {
      const payload = {
        ...add_gift_card,
        request: {
          ...data,
          
          amount: Number(data.amount) * 100,
        },
      };
      send(payload);
    }
    if (prop.showModal === "edit") {
      const payload = {
        ...edit_gift_card,
        request: {
          card_id: prop.editData.id,
          is_active: data.is_active ? "1" : "0",
          amount: Number(data.amount)*100,
        },
      };
      send(payload);
    }
  };

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        <InputField
          name={"Amount"}
          placeholder={"Enter Amount"}
          register={{
            ...register("amount", {
              required: "Amount is required",
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Min. amount must be a number",
              },
            }),
          }}
          required
          error={errors.amount}
          inputClassName="h-9"
          className="my-2 "
        />
         {prop.showModal === "edit" && (
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        )}
      </FormPopup>
    </>
  );
};

export default Form;
