import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  IAddCouponDetails,
  ICouponDetails,
  IToggleModalTypes,
} from "../../../utils/types";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import { add_coupon, edit_coupon } from "../../../api/websocket/payload";
import FormPopup from "../../components/popup/FormPopup";
import InputField from "../../components/input/InputField";
import moment from "moment";
import Dropdown from "../../components/dropdown";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: ICouponDetails;
  isEditable: boolean;
}
const Form: React.FC<IForm> = (prop) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<IAddCouponDetails>();
  const { send } = useWebSocket();
  
const today = new Date();

  const dropdownList = [
    { value: "fixed", label: "Fixed" },
    { value: "percentage", label: "Percentage" },
  ];
  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Coupon`,
    submit: "Submit",
    close: "Cancel",
  };
  const [ usage_limit_state, setusage_limit_state ] = useState("")
  const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

  useEffect(() => {
    if (prop.showModal === "edit") {
      reset(prop.editData); // Resets the form values to prop.editData
    } else {
      reset({ name: "", code: "",description:'',discount_type:'',discount_value:'',expiry_date:'',min_amount:'',usage_limit:'' }); // Resets the form values to prop.editData
    }
  }, [prop.showModal, reset, prop.editData]);

  // empty space validation fucntion
  const EmptySpaceFieldValid = (e:any) => {
    if (e.target.value.length === 0 && e.which === 32) {
      return e.preventDefault();
    };
  }

  const onSubmit = (data: IAddCouponDetails) => {
    const formattedDate = data.expiry_date
      ? moment(data.expiry_date).format("YYYY-MM-DD HH:mm:ss")
      : "";
    if (prop.showModal === "add") {
      const payload = {
        ...add_coupon,
        request: {
          ...data,
          expiry_date: formattedDate,
          usage_limit: usage_limit_state === "" ? null : usage_limit_state,
          min_amount: Number(data.min_amount),
          discount_value: Number(data.discount_value),
        },
      };

      send(payload);
    }
    if (prop.showModal === "edit") {
      const payload = { ...edit_coupon, request:{name:data.name, coupon_id: prop.editData.id,description:data.description,is_active:data.is_active?'1':'0'} };
      send(payload);
    }
  };

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        <InputField
          name={"Coupon Name"}
          register={register("name", {
            required: "Coupon name is required",
            maxLength: {
              value: 256,
              message: "Coupon name cannot exceed 256 characters",
            },
          })}
          required
          placeholder={"Enter Coupon name"}
          inputClassName="h-9"
          error={errors.name}
        />

        {prop.showModal!=='edit'&&<><InputField
          name={"Code"}
          register={register("code", {
            required: " Code is required",
            maxLength: {
              value: 20,
              message: "Code must be in 4-20 characters",
            },
            minLength: {
              value: 4,
              message: "Code must be in 4-20 characters",
            },
          })}
          required
          placeholder={"Enter  Code"}
          inputClassName="h-9"
          error={errors.code}
        />

        <Controller
          name={"discount_type"}
          control={control}
          rules={{
            required: "Discount type is required",
            validate: (value: any) =>
              (typeof value === "string" && value.trim().length > 0) ||
              "Discount type cannot be empty",
          }}
          render={({ field, fieldState: { error } }) => (
            <Dropdown
              label="Select Discount type"
              options={dropdownList}
              value={field.value}
              onChange={(value: any) => {
                field.onChange(value);
                setValue("discount_type", value);
              }}
              name={field.name}
              className="py-[0.43rem] w-full"
              errors={error}
              required
              isDynamic
            />
          )}
        />
        <div className="flex justify-between gap-4">
          <InputField
            name={"Discount value"}
            register={register("discount_value", {
              required: "Discount value is required",
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Discount value must be a number",
              },
            })}
            required
            placeholder={"Enter Discount value"}
            inputClassName="h-9"
            className="w-full"
            error={errors.discount_value}
          />
          <div className="w-full">
            <label
              htmlFor="date"
              className="block font-medium text-gray-600 text-sm mb-[1px]"
            >
              Expiry Date
            </label>
            <Controller
              name="expiry_date"
              control={control}
              defaultValue={""}
              // rules={{
              //   required: "Expiry date is required",
              // }}
              render={({ field }) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date) => field.onChange(date)}
                  minDate={today}
                  timeIntervals={15}
                  timeFormat="HH:mm"
                  selected={field.value}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="lg:w-52 px-3 text-sm py-2 border border-gray-300 rounded-md  focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              )}
            />

            <>
              {errors.expiry_date?.message && (
                <p className="text-xs mx-1 text-red-500">
                  {errors.expiry_date.message.toString()}
                </p>
              )}
            </>
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <InputField
            name={"Min. amount"}
            required
            register={register("min_amount", {
              required: "Min. amount is required",
              minLength:{
                value: 1,
                message: "Min. amount must be greater than 0",
              },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Min. amount must be a number",
              },
            })}
            
            className="w-full"
            placeholder={"Enter Min. amount"}
            inputClassName="h-9"
            error={errors.min_amount}
          />
          {/* <InputField
            name={"Usage limit"}
            register={register("usage_limit", {
             
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Usage limit must be a number",
              },
            })}
            
            placeholder={"Enter Usage limit"}
            inputClassName="h-9"
            className="w-full"
            error={errors.usage_limit}
          /> */}
          <div className="w-full">
          <p className="font-medium text-gray-600 text-sm mb-[1px]">
          Usage Limit
          </p>
           <input
              type="number" 
              className="COMMNfILED inputBoxs"
              value={usage_limit_state} 
              onChange={(e) => setusage_limit_state(e.target.value.replace(/^0/, ""))}
              onKeyDown={(e) => {
                  EmptySpaceFieldValid(e)
                  exceptThisCostSymbols.includes(e.key) && e.preventDefault()
              }}
              placeholder="Enter Usage Limit" 
              // maxLength={2}
              // max={2}
            />
          </div>
        </div></>}
        
        <InputField
          name={"Description"}
          register={register("description", {
            
            maxLength: {
              value: 2000,
              message: " Description cannot exceed 2000 characters",
            },
          })}
          
          placeholder={"Enter Description"}
          useFor="textarea"
          className="w-full"
          error={errors.description}
        />
        {prop.showModal !== "add" && (
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        )}
      </FormPopup>
    </>
  );
};

export default Form;
