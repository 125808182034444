/*eslint-disable*/
import React, { useEffect, useState } from "react";
import FormPopup from "../../../components/popup/FormPopup";
import InputField from "../../../components/input/InputField";
import { IAddKitsTypes, IToggleModalTypes } from "../../../../utils/types";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  add_Kit,
  edit_kit,
  product_kits_lists,
} from "../../../../api/websocket/payload";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import Dropdown from "../../../components/DropdownN";
import { RxCross2 } from "react-icons/rx";

interface IForm {
  showModal: string;
  toggleModal: (state: IToggleModalTypes) => void;
  editData: IAddKitsTypes;
  isEditable: boolean;
}

const Form: React.FC<IForm> = (prop) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    unregister,
  } = useForm<IAddKitsTypes>({
    defaultValues: {
      product_data: [{ id: "", name: "", price: "", value: "" }]
    }
  });
  const { product_list_all } = usePosterReducers();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "product_data"
  });
  const { send, isConnect } = useWebSocket();
  const formContaint = {
    title: `${prop.showModal === "edit" ? "Edit" : "Add"} Kit`,
    submit: "Submit",
    close: "Cancel",
  };

  useEffect(() => {
    if (prop.showModal === "edit") {
      const updatedData:any = {
        ...prop.editData,
        product_data: prop.editData?.product_data?.map(product => ({
          id: product?.id,
          name: product?.name || "",
          price: Number(product?.price) / 100,
          value: product?.value || ""
        }))
      };
      reset(updatedData); // Resets the form values to the updatedData
    } else {
      reset({ name: "", price: "", product_data: [{ id: "", name: "", price: "", value: "" }] });
    }
  }, [prop.showModal]);

  const extractedNames = product_list_all?.data?.filter((elm) => elm?.product_type === "single")?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (isConnect) {
      const message1 = product_kits_lists;
      send(message1);
    }
  }, [isConnect]);

  const removeDropdown = (index: number) => {
    remove(index);
  };

  const onSubmit = (data: IAddKitsTypes) => {
    if (prop.showModal === "add") {
      const payload = {
        ...add_Kit,
        request: {
          name: data.name,
          product_data: data?.product_data?.map((item) => {
            return {
              ...item,
              price: parseFloat(item.price) * 100,
            };
          }), // Collect product IDs from dropdowns
          description: data.description,
        },
      };
      send(payload);
    }

    if (prop.showModal === "edit") {
      const Data = {
        kit_id: prop.editData.id,
        name: data.name,
        product_data: data?.product_data?.map((item) => {
          return {
            ...item,
            price: parseFloat(item.price) * 100,
          };
        }),
        is_active: data.is_active ? "1" : "0",
        description: data.description,
      };
      const payload = { ...edit_kit, request: Data };
      send(payload);
    }
  };

  const addDropdown = () => {
    append({ id: "", name: "", price: "", value: "" });
  };

  const handleDropdownChange = (index: number, value: any) => {
    setValue(`product_data.${index}.id`, value);
    clearErrors(`product_data.${index}.id`);
  };

  return (
    <>
      <FormPopup
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        containt={formContaint}
        showModal={prop.showModal}
        toggleModal={prop.toggleModal}
      >
        <InputField
          name={"Kit Name"}
          register={register("name", {
            required: "kit name is required",
            maxLength: {
              value: 256,
              message: "kit name cannot exceed 256 characters",
            },
          })}
          required
          placeholder={"Enter Kit Name"}
          inputClassName="h-9"
          error={errors.name}
        />

        <div className="flex justify-between items-start select_item_wrapper">
          <div style={{ width: "80%" }}>
            {fields.map((field, index) => (
              <div key={field.id} className="flex items-center gap-3">
                <div className="flex items-center gap-3" style={{ width: "100%" }}>
                  <Controller
                    name={`product_data.${index}.id`}
                    control={control}
                    defaultValue={field.id}
                    rules={{
                      required: "Product is required",
                      validate: (value: any) =>
                        value.trim().length > 0 || "Product cannot be empty",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <Dropdown
                        label={`Select Product ${index + 1}`}
                        options={extractedNames}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                          handleDropdownChange(index, value);
                          clearErrors(`product_data.${index}.id`);
                        }}
                        name={field.name}
                        className="py-[0.43rem]"
                        isDynamic
                        required
                        lableName={"product_select"}
                        errors={errors.product_data && errors.product_data[index]?.id} // Pass error message to Dropdown
                      />
                    )}
                  />
                  <InputField
                    name={"Price"}
                    register={register(`product_data.${index}.price`, {
                      pattern: {
                        value: /^\d+(\.\d+)?$/,
                        message: "price must be a number",
                      },
                      required: "price must be a number",
                    })}
                    placeholder={"Enter Price"}
                    inputClassName="h-9"
                    className="w-24 prci_column_width"
                    lableName={"Price_select"}
                    required
                    error={errors.product_data && errors.product_data[index]?.price}
                  />
                </div>
                {index !== 0 && (
                  <button
                    type="button"
                    onClick={() => removeDropdown(index)}
                    className="h-fit w-fit text-sm font-medium border border-red-600 rounded-full text-red-600"
                  >
                    <RxCross2 />
                  </button>
                )}
              </div>
            ))}
          </div>
          <button
            type="button"
            className="border rounded-md hover:border-primary hover:text-primary py-1 text-start px-2 text-sm"
            onClick={addDropdown}
          >
            + Add
          </button>
        </div>

        <InputField
          name={"Kit Description"}
          register={register("description", {
            required: "Description is required",
            maxLength: {
              value: 2000,
              message: "Description cannot exceed 2000 characters",
            },
          })}
          required
          placeholder={"Enter Kit Description"}
          inputClassName=""
          error={errors.description}
          useFor="textarea"
        />

        {prop.showModal !== "add" && (
          <div>
            <p className="font-medium text-gray-600 text-sm my-[2px]">Status</p>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                {...register("is_active")}
              />
              <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        )}
      </FormPopup>
    </>
  );
};

export default Form;
