/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import List from "./list";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import TableLayout from "../../components/TableLayout";
import { edit_user, user_lists } from "../../../api/websocket/payload";
import { setUserList } from "../../../../redux/actions/action";
import { IToggleWarnModalTypes, IUserDetail } from "../../../utils/types";
import WarnPopup from "../../components/popup/WarnPopup";
import { editStatusContaint } from "../../../utils/staticdata";
import { toast } from "react-toastify";

const UserManagement = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const [EditStatus, setEditStatus] = useState<IUserDetail>();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const dispatch = useDispatch();
  const toggleWarn = (e: any, item: any, type: string) => {
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        user_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
      };

      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const editUser = () => {
    const payload = { ...edit_user, request: EditStatus };
    send(payload);
  };
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === user_lists.url) {
          dispatch(setUserList(receivedMessage?.response?.data));
        }
        if (receivedMessage?.url === edit_user.url) {
          setShowWarn("hide");
          send(user_lists)
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = user_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      user_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isFilter
      isAdd={false}
      title={"User List"}
      payload={user_lists}
    >
      <List toggleWarn={toggleWarn} />
      <WarnPopup
        onSubmit={editUser}
        containt={editStatusContaint}
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default UserManagement;
