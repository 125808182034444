import React from 'react'
interface IFormTypes {
    children: React.ReactNode;
    handleSubmit:Function;
  onSubmit?: Function;
  containt:{
    title:string,
    submit:string,
  }
  
  }
const FormLayout:React.FunctionComponent<IFormTypes> = (prop) => {
  return (
    <div className='overflow-x-auto h-full'>
      {/* <div> */}
      <div className='p-3'>
        <p className='font-semibold text-lg text-gray-500'>{prop.containt.title}</p>
        <form onSubmit={prop.handleSubmit(prop.onSubmit)}>
        {prop.children}
        <div className="flex items-center justify-center p-3 md:p-3 mb-14">
                  <button
                    type="submit"
                   
                    className="text-white bg-blue-700 hover:bg-blue-800  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    {prop.containt.submit}
                  </button>
                  
                </div>
        </form>

    </div>
      {/* </div> */}
    </div>
  )
}

export default FormLayout