import React from "react";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import ListComp from "../../components/ListComp";
import { dateFormat } from "../../../utils/common";
import {  payment_lists } from "../../../api/websocket/payload";
interface IList {}

const List: React.FunctionComponent<IList> = (props) => {
  const { payment_list } = usePosterReducers();
  const header = [
    "transaction_id",
    'payment type',
    'name',
    'email',
    "amount ($)",
    "Created At",
    "payment status",
  ];

  return (
    <ListComp
      payLoad={payment_lists}
      pages={payment_list?.pagination}
      header={header}
    >
      {payment_list?.data?.length !== 0 &&
        payment_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td className="py-3">{item.transaction_id}</td>
            <td className="py-3">{item.payment_type??'-'}</td>
            <td className="py-3">{item.order_details?.username??'-'}</td>
            <td className="py-3">{item.order_details?.email??'-'}</td>
            <td className="py-3">{item.amount&&(Number(item.amount)/100).toFixed(2)}</td>
            <td>{dateFormat(item.created_at)}</td>
            <td className="px-4  text-sm py-2">
              {item.is_paid?'Paid':'Unpaid'}
            </td>

            

            
          </tr>
        ))}
    </ListComp>
  );
};

export default List;
