/* eslint-disable */
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import List from "./list";
import { IPages, IToggleWarnModalTypes } from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  delete_page,
  edit_page,
  page_lists,
} from "../../../../api/websocket/payload";
import { setPageslist } from "../../../../../redux/actions/action";
import { useNavigate } from "react-router-dom";
import { ADD_INFO_PAGES_URL } from "../../../../constant/url";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  deletePageContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";

const InfoPages = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<IPages>();
  const toggleWarn = (e: any, item: any, type: string) => {

    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        page_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        title: item?.title,
        description: item?.description,
        type: item?.type,
      };
      setEditStatus(Data);

      setShowWarn("edit");
    }
  };

  const deletePage = () => {
    const data = { ...delete_page, request: { page_id: id } };
    send(data);
  };

  const updateStatus = () => {
    const data = { ...edit_page, request: EditStatus };
    send(data);
  };

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === "page_listing") {
          if (receivedMessage?.response?.status === 200)
            dispatch(setPageslist(receivedMessage.response.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setPageslist(initialPage));
        }
        if (receivedMessage.url === "page_delete") {
          if (receivedMessage?.response?.status === 200) {
            send(page_lists);
            toast.success(receivedMessage.response.msg);
          }
          setShowWarn("hide");
        }
        if (receivedMessage.url === "page_update") {
          if (receivedMessage?.response?.status === 200) {
            send(page_lists);
            toast.success(receivedMessage.response.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = page_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      page_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isAdd
      isFilter
      title={"Pages"}
      payload={page_lists}
      handleOpen={() => navigate(ADD_INFO_PAGES_URL)}
    >
      <List toggleWarn={toggleWarn} />
      <WarnPopup
        toggleModal={toggleWarn}
        onSubmit={showWarn !== "edit" ? deletePage : updateStatus}
        containt={showWarn !== "edit" ? deletePageContaint : editStatusContaint}
        showModal={showWarn}
      />
    </TableLayout>
  );
};

export default InfoPages;
