/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  ICategoryTypes,
  IProductSubscriptionDetail,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import {
  deleteproductSubscriptionContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  add_product_subscription_list,
  delete_product_subscription_list,
  edit_product_subscription_list,
  product_subscription_lists,
} from "../../../../api/websocket/payload";
import Form from "./form";
import { setProductSubscriptionlist } from "../../../../../redux/actions/action";

const ProductSubscription = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [EditStatus, setEditStatus] = useState<ICategoryTypes>();
  const [id, setid] = useState("");
  const [editData, setEditData] = useState<any>();
  const toggleWarn = (e: any, item: any, type: string) => {


    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        subscription_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        name: item?.name,
        discount: item?.discount,
        interval: item?.interval,
      };

      setEditStatus(Data);
      // setEditData({ ...EditStatus, request: { is_active: e.target.checked } });
      setShowWarn("edit");
    }
  };

  const deleteProductSubscription = () => {
    if (isConnect) {
      const message = {
        ...delete_product_subscription_list,
        request: { subscription_id: id },
      };
      send(message);
    }
  };

  const editPoductSubScription = () => {
    const payload = { ...edit_product_subscription_list, request: EditStatus };
    send(payload);
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (
    item: IProductSubscriptionDetail,
    type: IToggleModalTypes
  ) => {
    toggleModal(type);
    setEditData(item);
  };
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage?.url === product_subscription_lists.url) {
        if (receivedMessage?.response?.status === 200)
          dispatch(setProductSubscriptionlist(receivedMessage?.response?.data));
        if (receivedMessage?.response?.status === 400)
          dispatch(setProductSubscriptionlist(initialPage));
      }

      if (receivedMessage?.url === add_product_subscription_list.url) {
        if (receivedMessage?.response?.status === 200) {
          send(product_subscription_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
      }
      if (receivedMessage?.url === edit_product_subscription_list.url) {
        if (receivedMessage?.response?.status === 200) {
          send(product_subscription_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === delete_product_subscription_list.url) {
        if (receivedMessage?.response?.status === 200) {
          send(product_subscription_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message1 = product_subscription_lists
      send(message1);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      product_subscription_lists.request = {
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isFilter
      isAdd
      payload={product_subscription_lists}
      handleOpen={() => toggleModal("add")}
      title={"Product Subscription"}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />
      <WarnPopup
        onSubmit={
          showWarn === "delete"
            ? deleteProductSubscription
            : editPoductSubScription
        }
        containt={
          showWarn === "delete"
            ? deleteproductSubscriptionContaint
            : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
    </TableLayout>
  );
};

export default ProductSubscription;
