/*eslint-disable*/
import React, { useEffect, useState } from "react";
import InputField from "./input/InputField";
import Button from "./Button";
import { useWebSocket } from "../../api/websocket/WebSocketContext";
import { Controller, useForm } from "react-hook-form";
import { ITableLayoutTypes } from "../../utils/types";
import Dropdown from "./dropdown";
import { useLocation } from "react-router-dom";
import { GIFT_CARDS_URL, USER_GIFT_CARD_URL, SEO_OPTIMIZATION_URL, ORDER_MANAGEMENT_URL } from "../../constant/url";
import { category_lists, order_lists, variant_lists } from "../../api/websocket/payload";
import { LiaFileCsvSolid } from "react-icons/lia";
import { BsFiletypeXlsx } from "react-icons/bs";
import Calender from "./Calender";
import { formatDateTime } from "../../utils/common";
interface IListTypes {
  children: React.ReactNode;
  handleOpen?: Function;
  title: string;
  payload?: any;
  isAdd?: boolean;
  isFilter?: boolean;
}
const TableLayout: React.FC<IListTypes> = (props) => {
  const { send, receivedMessage } = useWebSocket();
  const { register, handleSubmit, control,watch } = useForm<ITableLayoutTypes>();
 
  const { isConnect } = useWebSocket();
  const [categoryName, setcategoryName] = useState<any>([]);
  const [variantName, setvariantName] = useState<any>([]);
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Submit the form programmatically
      event.target.form.requestSubmit();
    }
  };

  

  const loc = useLocation();
  let statusOptions: any;

  if (
    loc.pathname === "/order-management" ||
    loc.pathname === "/payment-management"
  ) {
    statusOptions = [
      { value: "all", label: "All" },
      { value: "paid", label: "Paid" },
      { value: "unpaid", label: "Unpaid" },
    ];
  } else
    statusOptions = [
      { value: "all", label: "All" },
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ];

  const userLimit = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
    { value: 60, label: 60 },
    { value: 70, label: 70 },
    { value: 80, label: 80 },
    { value: 90, label: 90 },
    { value: 100, label: 100 },
  ];

  useEffect(() => {
    if (receivedMessage?.url === "category_listing") {
     if(receivedMessage?.response?.status===200){
      if (receivedMessage?.response?.data?.data?.length !== 0) {
        const extractedNames = receivedMessage?.response?.data?.data?.map(
          (item: any) => ({
            label: item.name,
            value: item.id,
          })
        );

        setcategoryName([{ value: "", label: "All" },...extractedNames]);
      }
     }
     if(receivedMessage?.response?.status===400){
      setcategoryName([]);
     }
    }
    if (receivedMessage?.url === "variant_listing") {
      if (receivedMessage?.response?.data?.data?.length !== 0 && Object.keys(receivedMessage?.response?.data)?.length > 0) {
        const extractedNames = receivedMessage?.response?.data?.data
        ?.filter((elm: { is_active: boolean; }) => elm?.is_active === true)
        .sort((a: { quantity: number; }, b: { quantity: number; }) => b.quantity - a.quantity)
        .map((item: any) => ({ 
          label: item.name + ` - (${item?.quantity})  $${(Number(item?.price) / 100).toFixed(2)}`, 
          value: item.id 
        }));
        setvariantName([{ value: "", label: "All" },...extractedNames]);
      } else{
        setvariantName([]);
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect&&loc.pathname==='/product-management/product') {
      const message1 = category_lists;
      send(message1);
    }
  }, [isConnect]);

  useEffect(() => {
    if (isConnect&&loc.pathname==='/product-management/product') {
      const message1 = variant_lists;
      send(message1);
    }
  }, [isConnect]);

  function onsubmit(data: ITableLayoutTypes) {
    // Create a copy of the current payload and update it
    const updatedPayload = { ...props.payload };
    updatedPayload.request.search = data.search;
    updatedPayload.request.filter = data.filter;
    updatedPayload.request.limit = data.limit;
    if(data?.start_date)
    updatedPayload.request.start_date = formatDateTime(data.start_date);
    if(data?.end_date)
    updatedPayload.request.end_date = formatDateTime(data.end_date);
    updatedPayload.request.page = "1";
    if ('category_id' in data) updatedPayload.request.category_id = data.category_id;
    if ('variant_id' in data) updatedPayload.request.variant_id = data.variant_id;
    send(updatedPayload);
  }

  const handleSelect = handleSubmit(onsubmit);
  const callDownloadAttachment=(type:string)=>{
    order_lists.request.is_export=true;
    order_lists.request.file_type = type;
    send(order_lists)
  }

const startDate=watch('start_date')
const endDate=watch('end_date')
  useEffect(()=>{
    handleSubmit(onsubmit)();
  },[startDate,endDate])

  function callDateChange(e:any){

  }
  return (
    <div className="w-full h-full  bg-white rounded-md overflow-hidden">
      {/* <div className="flex border-b">
        <p className={`px-6 py-2 cursor-pointer `}>{props.title}</p>
      </div> */}
      <form
        action=""
        onSubmit={(e) => {
          handleSubmit(onsubmit)(e);
        }}
      >
        <div className="lg:flex my-2 items-center justify-between mx-3 ">
          {loc.pathname !== GIFT_CARDS_URL &&
            loc.pathname !== USER_GIFT_CARD_URL &&
            loc.pathname !== SEO_OPTIMIZATION_URL && (
              <InputField
                name={""}
                register={register("search")}
                placeholder={`Search by ${props.title} & press enter`}
                useFor="search"
                inputClassName="h-8 lg:w-72 w-full"
                className="h-10 mt-2"
                onEnterClick={handleKeyDown}
              />
            )}

          <div className="w-full">
            <div className="flex justify-between lg:justify-end  gap-2">
              {loc.pathname === "/product-management/product" && (
                <>
                  {" "}
                  <Controller
                    name="category_id"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        label=""
                        options={categoryName}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleSelect(e); // Use the value from the event
                        }}
                        name={field.name}
                        // onSelect={handleSelect}
                        className="py-2"
                      />
                    )}
                  />
                  <Controller
                    name="variant_id"
                    control={control}
                    // defaultValue="all"
                    render={({ field }) => (
                      <Dropdown
                        label=""
                        options={variantName}
                        value={field.value}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleSelect(e); // Use the value from the event
                        }}
                        name={field.name}
                        // onSelect={handleSelect}
                        className="py-2"
                      />
                    )}
                  />
                </>
              )}
               {loc.pathname === ORDER_MANAGEMENT_URL && (
                <div className="mt-[0.125rem] flex gap-2">
                <Calender name="start_date" placeholder="Start Date" control={control} showIcon onChange={(e:any)=>callDateChange(e)} maxDate={endDate}/>
                <Calender name="end_date" placeholder="End Date" control={control} showIcon onChange={onsubmit} minDate={startDate}/>
                <Button label="CSV" className="bg-primary h-[36px]" icon={LiaFileCsvSolid} onCLick={()=>callDownloadAttachment('csv')}/>
                <Button label="XLSX" className="bg-primary h-[36px]" icon={BsFiletypeXlsx} onCLick={()=>callDownloadAttachment('xlsx')}/>
                </div>
              )}
              {props.isFilter && loc.pathname !== USER_GIFT_CARD_URL && (
                <Controller
                  name="filter"
                  control={control}
                  defaultValue="all"
                  render={({ field }) => (
                    <Dropdown
                      label=""
                      options={statusOptions}
                      value={field.value}
                      onChange={(e: any) => {
                        field.onChange(e);
                        handleSelect(e); // Use the value from the event
                      }}
                      name={field.name}
                      // onSelect={handleSelect}
                      className="py-2"
                    />
                  )}
                />
              )}
              <Controller
                name="limit"
                control={control}
                defaultValue={10}
                render={({ field }) => (
                  <Dropdown
                    label=""
                    options={userLimit}
                    value={field.value}
                    onChange={(e: any) => {
                      field.onChange(e);
                      handleSelect(e); // Use the value from the event
                    }}
                    name={field.name}
                    className="py-2"
                  />
                )}
              />
              {props.isAdd && (
                <Button
                  onCLick={props.handleOpen}
                  label={`+ Add ${props.title}`}
                  className="bg-[#2737FF] "
                />
              )}
            </div>
          </div>
        </div>
      </form>

      {props.children}
    </div>
  );
};

export default TableLayout;
