/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  ICategoryTypes,
  IKitDetails,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import {
  deleteKitContaint,
  editStatusContaint,
} from "../../../../utils/staticdata";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  add_Kit,
  delete_kit,
  edit_kit,
  kit_lists,
  product_kits_lists,
} from "../../../../api/websocket/payload";
import Form from "./form";
import {
  setKitlist,
  setAllProductlist,
} from "../../../../../redux/actions/action";

const Kit = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [EditStatus, setEditStatus] = useState<ICategoryTypes>();
  const [id, setid] = useState("");
  const [editData, setEditData] = useState<any>();
  
  const toggleWarn = (e: any, item: any, type: string) => {

    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        kit_id: item?.id,
        is_active: e.target.checked ? "1" : "0",
        name: item?.name,
      };

      setEditStatus(Data);
      // setEditData({ ...EditStatus, request: { is_active: e.target.checked } });
      setShowWarn("edit");
    }
  };

  const deleteKit = () => {
    if (isConnect) {
      const message = { ...delete_kit, request: { kit_id: id } };
      send(message);
    }
  };

  const editKit = () => {
    const payload = { ...edit_kit, request: EditStatus };
    send(payload);
  };
  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IKitDetails, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };

  
  useEffect(() => {
    if (isConnect) {
      // if (receivedMessage?.response?.status ===200) {
      if (receivedMessage?.url === kit_lists.url) {
        dispatch(setKitlist(receivedMessage?.response?.data));
      }
      if (receivedMessage?.url === product_kits_lists.url) {
        if(receivedMessage?.request?.limit === 5000) {
          dispatch(setAllProductlist(receivedMessage?.response?.data));
        }
      }
      if (receivedMessage?.url === add_Kit.url) {
        if (receivedMessage?.response?.status === 200) {
          send(kit_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
      }
      if (receivedMessage?.url === edit_kit.url) {
        if (receivedMessage?.response?.status === 200) {
          send(kit_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        toggleModal("hide");
        setShowWarn("hide");
      }
      if (receivedMessage?.url === "kit_delete") {
        if (receivedMessage?.response?.status === 200) {
          send(kit_lists)
          toast.success(receivedMessage?.response?.msg);
        }
        setShowWarn("hide");
      }
    }
  }, [isConnect, receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = kit_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    if (isConnect) {
      const message = product_kits_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      kit_lists.request = {
        filter: "all",
        limit: 10,
        page: 1,
        search: "",
      };
    };
  }, []);

  return (
    <TableLayout
      isFilter
      isAdd
      payload={kit_lists}
      handleOpen={() => toggleModal("add")}
      title={"Kits"}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteKit : editKit}
        containt={
          showWarn === "delete" ? deleteKitContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />

      <Form
        isEditable
        showModal={showModal}
        toggleModal={toggleModal}
        editData={editData}
      />
    </TableLayout>
  );
};

export default Kit;
