import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import React from "react";
import ListComp from "../../../components/ListComp";
import { usePosterReducers } from "../../../../../redux/getdata/usePostReducer";
import { dateFormat } from "../../../../utils/common";
import {
  retail_lists,
} from "../../../../api/websocket/payload";

interface IList {
  edit: Function;
  toggleWarn: Function;
  onEdit: Function;
}

const List: React.FunctionComponent<IList> = (props) => {
  const { retail_list } = usePosterReducers();
  const header = [
    "landmark",
    "city",
    "state",
    "postcode",
    "address",
    "site_url",
    "address url",
    "created at",
    "ststus",
    "actions",
  ];
  return (
    <ListComp
      payLoad={retail_lists}
      pages={retail_list?.pagination}
      header={header}
    >
      {retail_list?.data?.length !== 0 &&
        retail_list?.data?.map((item, index) => (
          <tr key={index} className="border-b text-sm rounded-md  ">
            <td>{item.landmark}</td>
            <td className="p-3">{item.city}</td>
            <td>{item.state}</td>
            <td>{item.postcode}</td>
            {/* <td className="max-w-64">{item.address}</td>
            <td className="max-w-64">{item.site_url}</td> */}
            {/* <td className="max-w-64 h-auto bg-black">{item.address_url}</td> */}
            <td>
              <div className="flex  items-center justify-center border p-1 mx-2 my-1 rounded-md h-full">
                <textarea
                  id="product-name"
                  className="rounded focus:outline-none text-start w-full h-full text-sm resize-none leading-7"
                  value={item.address} // Join items with newline for vertical display
                  readOnly
                />
              </div>
            </td>
            <td>
              <div className="flex  items-center justify-center border p-1 mx-2 my-1 rounded-md h-full">
                <textarea
                  id="product-name"
                  className="rounded focus:outline-none text-start w-full h-full text-sm resize-none leading-7"
                  value={item.site_url} // Join items with newline for vertical display
                  readOnly
                />
              </div>
            </td>
            <td>
              <div className="flex  items-center justify-center border p-1 mx-2 my-1 rounded-md h-full">
                <textarea
                  id="product-name"
                  className="rounded focus:outline-none text-start w-full h-full text-sm resize-none leading-7"
                  value={item.address_url} // Join items with newline for vertical display
                  readOnly
                />
              </div>
            </td>
            <td>{dateFormat(item.created_at)}</td>

            <td className="px-4  text-sm py-2">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  onChange={()=>{}}
                  checked={item.is_active}
                  onClick={(e) => props.toggleWarn(e, item, "edit")}
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </td>
            <td>
              <div className="flex justify-center items-center gap-2">
                <button
                  onClick={() => props.onEdit(item, "edit")}
                  className="p-2 text-base text-black rounded-md"
                >
                  <CiEdit />
                </button>
                <button
                  onClick={() => props.toggleWarn("", item.id, "delete")}
                  className="p-2  text-base text-black rounded-md"
                >
                  <AiOutlineDelete />
                </button>
              </div>
            </td>
          </tr>
        ))}
    </ListComp>
  );
};

export default List;
