/* eslint-disable */
import { useEffect } from "react";
import { useWebSocket } from "../../../api/websocket/WebSocketContext";
import { usePosterReducers } from "../../../../redux/getdata/usePostReducer";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { user_details } from "../../../api/websocket/payload";
import { setUserDetail } from "../../../../redux/actions/action";
import { dateFormat } from "../../../utils/common";
import { initialUserDetail } from "../../../utils/staticdata";
import ListComp from "../../components/ListComp";

const ViewUser = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();

  const { user_detail } = usePosterReducers();

  const param = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === user_details.url) {
          dispatch(setUserDetail(receivedMessage?.response?.data));
        }
      }
    }
    return () => {
      dispatch(setUserDetail(initialUserDetail));
    };
  }, [receivedMessage, dispatch]);

  useEffect(() => {
    if (isConnect) {
      const message2 = {
        ...user_details,
        request: { user_id: param.id },
      };

      send(message2);
    }
  }, [isConnect, receivedMessage?.url]);
  return (
    <div className="p-2 px-3 overflow-auto h-full">
      <p className="font-semibold text-lg text-gray-500 my-2 md:my-0">
        User Details
      </p>

      <div className="mt-3 md:mt-0">
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">User Name </p>:
          <p>{user_detail?.username}</p>
        </div>

        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">User Email </p>:
          <p>{user_detail?.email}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">User Display Name </p>:
          <p>{user_detail?.display_name}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Phone no. </p>:
          <p>{user_detail?.phone}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">User type </p>:
          <p>{user_detail?.user_type}</p>
        </div>

        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Created Date </p>:
          <p>{dateFormat(user_detail?.created_at)}</p>
        </div>

        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium"> Status </p>:
          <p>{user_detail?.is_active ? "Active" : "Inactive"}</p>
        </div>
        <div className="flex gap-2 my-4 text-sm">
          <p className="min-w-44 font-medium">Email Subscription status </p>:
          <p>{user_detail?.has_mail_subscription ? "Active" : "Inactive"}</p>
        </div>

      
        {user_detail?.addresses?.billingAddres?.length !== 0 &&
          (user_detail?.addresses !== null && (
            <div>
              <p className="font-semibold text-lg text-gray-500 my-1 mt-3 md:my-0">
                {" "}
                Billing Address{" "}
              </p>

              <ListComp
                header={["name", "email", "phone", "company name", "default"]}
              >
                {user_detail?.addresses?.billingAddres?.map((item, index) => (
                  <tr key={index} className="border-b text-sm rounded-md  ">
                    <td className="p-3  ">{item.name ?? "-"}</td>
                    <td className="p-3  ">{item.email ?? "-"}</td>
                    <td className="p-3  ">{item.phone ?? "-"}</td>
                    <td className="p-3  ">{item.company_name ?item.company_name :"-"}</td>
                    <td className="p-3  ">{item.default ? "Default" : "-"}</td>
                  </tr>
                ))}
              </ListComp>
            </div>
          ))}
        {user_detail?.addresses?.shippingAddress?.length !== 0 &&
          (user_detail?.addresses !== null && (
            <div className=" mt-4">
              <p className="font-semibold text-lg  text-gray-500 mt-3  md:my-0">
                {" "}
                Shipping Address{" "}
              </p>

              <ListComp
                header={["name", "company name", "default"]}
              >
                {user_detail?.addresses?.shippingAddress?.map((item, index) => (
                  <tr key={index} className="border-b text-sm rounded-md  ">
                    <td className="p-3  ">{item.name ?? "-"}</td>
                    <td className="p-3  ">{item.company_name ?item.company_name :"-"}</td>
                    <td className="p-3  ">{item.default ? "Default" : "-"}</td>
                  </tr>
                ))}
              </ListComp>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ViewUser;
