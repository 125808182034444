/* eslint-disable */
import { useEffect, useState } from "react";
import List from "./list";
import Form from "./form";
import TableLayout from "../../../components/TableLayout";
import WarnPopup from "../../../components/popup/WarnPopup";
import {
  IAddRetailStoreTypes,
  IReviewDetails,
  IToggleModalTypes,
  IToggleWarnModalTypes,
} from "../../../../utils/types";
import { useWebSocket } from "../../../../api/websocket/WebSocketContext";
import {
  deleteReviewContaint,
  editStatusContaint,
  initialPage,
} from "../../../../utils/staticdata";
import {
  add_seo,
  delete_seo,
  update_seo,
  seo_lists,
} from "../../../../api/websocket/payload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setSeolist } from "../../../../../redux/actions/action";
import { useNavigate } from "react-router-dom";

const SeoOptimization = () => {
  const { send, receivedMessage, isConnect } = useWebSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<IToggleModalTypes>("hide");
  const [showWarn, setShowWarn] = useState<IToggleWarnModalTypes>("hide");
  const [editData, setEditData] = useState<any>();
  const [id, setid] = useState("");
  const [EditStatus, setEditStatus] = useState<IAddRetailStoreTypes>();

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage?.url === seo_lists.url) {
          if (receivedMessage?.response?.status === 200)
            dispatch(setSeolist(receivedMessage?.response?.data));
          if (receivedMessage?.response?.status === 400)
            dispatch(setSeolist(initialPage));
        }
        if (receivedMessage?.url === add_seo.url) {
          if (receivedMessage?.response?.status === 200) {
            send(seo_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
        }
        if (receivedMessage?.url === update_seo.url) {
          if (receivedMessage?.response?.status === 200) {
            send(seo_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          toggleModal("hide");
          setShowWarn("hide");
        }
        if (receivedMessage.url === delete_seo.url) {
          if (receivedMessage?.response?.status === 200) {
            send(seo_lists)
            toast.success(receivedMessage?.response?.msg);
          }
          setShowWarn("hide");
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (isConnect) {
      const message = seo_lists;
      send(message);
    }
  }, [isConnect]);

  useEffect(() => {
    return () => {
      seo_lists.request = {
        limit: 10,
        page: 1,
      };
    };
  }, []);

  const toggleWarn = (e: any, item: any, type: string) => {

    if (type === "delete") {
      setid(item);
      setShowWarn("delete");
    }
    if (type === "hide") {
      setShowWarn("hide");
    }

    if (type === "edit") {
      const Data: any = {
        seo_id: item?.id,
        meta_title: item?.meta_title,
        meta_description: item?.meta_description,
        content: item?.content,
        keywords: item?.keywords,
        is_active: e.target.checked ? "1" : "0",
      };
      setEditStatus(Data);
      setShowWarn("edit");
    }
  };

  const deleteReview = () => {
    if (isConnect) {
      const message = { ...delete_seo, request: { seo_id: id } };
      send(message);
    }
  };

  const editReview = () => {
    const payload = { ...update_seo, request: EditStatus };
    send(payload);
  };

  const toggleModal = (state: IToggleModalTypes) => {
    setShowModal(state);
  };

  const onEdit = (item: IReviewDetails, type: IToggleModalTypes) => {
    toggleModal(type);
    setEditData(item);
  };

  return (
    <TableLayout
      isAdd
      isFilter
      title={"SEO"}
      payload={seo_lists}
      // handleOpen={() => toggleModal("add")}
      handleOpen={() => navigate('/content-management/seo-optimization/add-seo')}
    >
      <List toggleWarn={toggleWarn} edit={toggleModal} onEdit={onEdit} />

      <Form
        isEditable
        showModal={showModal}
        editData={editData}
      />
      <WarnPopup
        onSubmit={showWarn === "delete" ? deleteReview : editReview}
        containt={
          showWarn === "delete" ? deleteReviewContaint : editStatusContaint
        }
        showModal={showWarn}
        toggleModal={toggleWarn}
      />
    </TableLayout>
  );
};

export default SeoOptimization;

