/* eslint-disable */
// import React from 'react'
import { ISEOTypes } from '../../../../utils/types';
import FormLayout from '../../../components/FormLayout';
import InputField from '../../../components/input/InputField';
import { ISeoDetailsList } from '../../../../utils/types';
// import Dropdown from '../../../components/DropdownN';
import Dropdown from '../../../components/DropdownN';
import { Controller, useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { add_seo, update_seo } from '../../../../api/websocket/payload';
import { useWebSocket } from '../../../../api/websocket/WebSocketContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { usePosterReducers } from '../../../../../redux/getdata/usePostReducer';

interface IForm {
  showModal: string;
  editData: ISeoDetailsList;
  isEditable: boolean;
}

const AddSeoOptimization = (prop: IForm) => {
  const { control, reset, handleSubmit, register, watch, setValue ,formState:{errors}} =
  useForm<ISeoDetailsList>();
  const { send, receivedMessage, isConnect } = useWebSocket();
  const location = useLocation();
  let page_url = location.pathname.split("/")[3];
  const { seo_list } = usePosterReducers();
  const navigate = useNavigate();
  const Params = useParams();

  const pageTitleSeo = [
    { value: "Home", label: "Home" },
    { value: "Buy Cookies", label: "Buy Cookies" },
    { value: "Find Us", label: "Find Us" },
    { value: "About Us", label: "About Us" },
    { value: "Catering", label: "Catering" },
    { value: "Explore", label: "Explore" },
    { value: "My Account", label: "My Account" },
    { value: "Shipping", label: "Shipping" },
    { value: "Reviews", label: "Reviews" },
    { value: "Privacy Policy", label: "Privacy Policy" },
    { value: "Terms of Service", label: "Terms of Service" },
  ];
  
  const pageSubTitleSeo = [
    { value: "Retails Partners", label: "Retails Partners" },
    { value: "Community Events", label: "Community Events" },
  ];

  useEffect(() => {
    if (isConnect) {
      if (receivedMessage) {
        if (receivedMessage.url === "seo_add") {
          if (receivedMessage?.response?.status === 200)
            // toast.success(receivedMessage.response.msg);

          navigate('/content-management/seo-optimization');
        }

        if (receivedMessage.url === "seo_update") {
          if (receivedMessage?.response?.status === 200)
            // toast.success(receivedMessage.response.msg);

          navigate('/content-management/seo-optimization');
        }
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (page_url === "edit-seo") {
      const CurrentData = seo_list?.data?.filter((elm) => elm?.id === Params?.id)[0];
      reset(CurrentData)
    }
  }, [ page_url ])


  const onSubmit = (data: ISEOTypes) => {
    if(page_url === "add-seo") {
      const req = {
        ...add_seo,
        request: {
          ...data,
          page_title: data?.page_title,
          page_subtitle : data?.page_subtitle === "" ? null : data?.page_subtitle,
          meta_title : data?.meta_title,
          meta_description : data?.meta_description,
          content : data?.content,
          keywords : data?.keywords,
        },
      };
      send(req);
    } 
    if(page_url === "edit-seo") {
      const req = {
        ...update_seo,
        request: {
          // ...data,
          seo_id: Params?.id,
          // page_title: data?.page_title,
          // page_subtitle : data?.page_subtitle,
          meta_title : data?.meta_title,
          meta_description : data?.meta_description,
          content : data?.content,
          keywords : data?.keywords,
        },
      };
      send(req);
    }
  };

  return (
    <FormLayout
    handleSubmit={handleSubmit}
    onSubmit={onSubmit}
    containt={{
      title: "SEO Optimization",
      submit: "Submit",
    }}
  >

    {page_url === "edit-seo" ? (<>
        <div className='py-[0.43rem] w-full maincustom-dropdonn'>
          <label className="dropdown-label font-medium text-gray-600 text-sm mb-[1px]" htmlFor="page_title">Select Page title<span className="text-red-500"> *</span></label><br />
          {watch("page_title")}
        </div>
        {watch("page_subtitle") !== null && (<div className='py-[0.43rem] w-full maincustom-dropdonn'>
          <label className="dropdown-label font-medium text-gray-600 text-sm mb-[1px]" htmlFor="page_title">Select Page title<span className="text-red-500"> *</span></label><br />
          {watch("page_subtitle")}
        </div>)}
    </>) : (<>
        <Controller
          name={"page_title"}
          control={control}
          rules={{
            required: "Page title is required",
            validate: (value: any) =>
              (typeof value === "string" && value.trim().length > 0) ||
              "Page title cannot be empty",
          }}
          render={({ field, fieldState: { error } }) => (
            <Dropdown
              label="Select Page title"
              options={pageTitleSeo}
              value={field.value}
              onChange={(value: any) => {
                field.onChange(value);
                setValue("page_title", value);
                setValue("page_subtitle", "");
              }}
              name={field.name}
              className="py-[0.43rem] w-full"
              errors={error}
              required
              isDynamic
            />
          )}
        />
        {watch("page_title") === "Find Us" && (<Controller
          name={"page_subtitle"}
          control={control}
          rules={{
            required: "Page subtitle is required",
            validate: (value: any) =>
              (typeof value === "string" && value.trim().length > 0) ||
              "Page subtitle cannot be empty",
          }}
          render={({ field, fieldState: { error } }) => (
            <Dropdown
              label="Select Page subtitle"
              options={pageSubTitleSeo}
              value={field.value}
              onChange={(value: any) => {
                field.onChange(value);
                setValue("page_subtitle", value);
              }}
              name={field.name}
              className="py-[0.43rem] w-full"
              errors={error}
              required
              isDynamic
            />
          )}
        />)}
    </>)}
      
      <InputField
        name={"keywords"}
        register={register("keywords", {
          required: "keywords is required",
          maxLength: {
            value: 2000,
            message: "keywords cannot exceed 2000 characters",
          },
        })}
        required
        placeholder={"Enter keywords"}
        inputClassName="h-9"
        className="w-full"
        error={errors.keywords}
      />
      <InputField
        name={"Meta Title"}
        register={register("meta_title", {
          required: "content is required",
          maxLength: {
            value: 500,
            message: "Meta title cannot exceed 500 characters",
          },
        })}
        required
        placeholder={"Enter meta title"}
        inputClassName="h-9"
        className="w-full"
        error={errors.meta_title}
      />
      <InputField
          name={"Meta Description"}
          register={register("meta_description", {
            required: "meta description is required",
            maxLength: {
              value: 2000,
              message: "Meta description cannot exceed 2000 characters",
            },
          })}
          required
          placeholder={"Enter meta description"}
          useFor="textarea"
          className="w-full"
          error={errors.meta_description}
      />
      <InputField
        name={"Content"}
        register={register("content", {
          required: "content is required",
          maxLength: {
            value: 2000,
            message: "Content cannot exceed 2000 characters",
          },
        })}
        required
        placeholder={"Enter content"}
        inputClassName="h-9"
        className="w-full"
        error={errors.content}
      />
  </FormLayout>
  )
}

export default AddSeoOptimization