import axios from "axios";
import { toast } from "react-toastify";
import { ILoginTypes } from "../../utils/types";

const URL = process.env.REACT_APP_ENDPOINT_URL;

export function fetchData(
  url: string,
  method: any,
  headers: any = {},
  data: any = null,
  params: any = null
) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      headers,
      data,
      params,
    })
      .then((response) => {
        
        resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          const errorMessage = error.response.data.error || error.response.data.message || 'An error occurred';
          toast.error(errorMessage);
          console.error(
            `Request failed with status code: ${error.response.status} - ${errorMessage}`
          );

          reject(
            `Request failed with status code: ${error.response.status} - ${errorMessage}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          toast.error('No response received from server.');
          console.error('No response received:', error.request);
          reject('No response received from server.');
        } else {
          // Something happened in setting up the request that triggered an error
          toast.error(`Request failed: ${error.message}`);
          console.error('Request failed:', error.message);
          reject(`Request failed: ${error.message}`);
        }
      });
  });
}

export const getData = async (url: string,token:string): Promise<any> => {
  try {
    const data = await fetchData(`${URL}${url}`, "GET", {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });
    return data;
  } catch (error) {
    
  }
};

export const postData = async (
  url: string,
  data_req: any,
  isToken: string,
  content_type:"application/json"|"multipart/form-data"="application/json"
): Promise<any> => {
  try {
    const headers = {
      // Accept: "application/json",
      "Content-Type": content_type,
      'Authorization': 'Bearer ' + isToken ,
    };
    const data = await fetchData(`${URL}${url}`, "POST", headers, data_req);
    return data;
  } catch (error) {
    
  }
};



export const AuthReq = async (url: string, data_req: ILoginTypes): Promise<any> => {
  try {
    const data = await fetchData(`${URL}${url}`, "POST", {
      Accept: "application/json",
    }, data_req);
    return data;
  } catch (error) {
  
  }
};
